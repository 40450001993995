import { IconSend } from '@consumidor-positivo/aurora';
import { useConfirmProps } from '../ConfirmDialog/hook';

export const ReportConfirmDialog: useConfirmProps = {
  title: 'Relatório do meu CPF',
  message:
    'Atenção, você precisa esperar 24h para gerar um novo relatório e ser enviado para seu e-mail.',
  buttonFalse: 'Cancelar',
  buttonTrue: 'Enviar relatório',
  iconButtonTrue: <IconSend />,
};
