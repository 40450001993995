import { useLocation } from 'react-router-dom';
import { useTracking } from '@common/hooks/useTracking';
import { useEffect } from 'react';
import errorGauge from '@common/assets/illustration/error-gauge.svg';
import errorInfo from '@common/assets/illustration/error-info.svg';

interface UseErrorBannerProps {
  eventName: string;
}

export const useErrorBanner = ({ eventName }: UseErrorBannerProps) => {
  const { elementViewedEvent } = useTracking();
  const { pathname } = useLocation();

  const variants = {
    score: {
      src: errorGauge,
      alt: 'Medidor de score com um ponto de interrogação no centro',
      subtitle:
        'Ocorreu um erro e não conseguimos acessar suas informações de Score',
    },
    dados: {
      src: errorInfo,
      alt: 'Uma folha de documento com um X',
      subtitle: 'Ocorreu um erro e não conseguimos acessar seus dados',
    },

    dividas: {
      src: errorInfo,
      alt: 'Uma folha de documento com um X',
      subtitle: 'Ocorreu um erro e não conseguimos acessar suas dividas',
    },
  };

  useEffect(() => {
    elementViewedEvent({
      elementType: 'content',
      location: pathname,
      name: `Error - ${eventName}`,
    });
  }, []);

  return {
    variants,
  };
};
