import { RouteMap } from '@common/constants/RouteMap';
import './styles.scss';
import { Button, Text } from '@consumidor-positivo/aurora';
import CreditCardIcon from '@hub/assets/credit-card-toon.svg';
import { useNavigate } from 'react-router-dom';
import { useTracking } from '@common/hooks/useTracking';
import { getFromStorage, removeFromStorage } from '@common/utils/handleStorage';
import { StorageMap } from '@common/constants/StorageMap';
import useConfirmDialog from '@common/components/ConfirmDialog/hook';
import { createPortal } from 'react-dom';
import { MatchMakerCache } from '@hub/types/matchmaker';

export const FavoriteEmpty = () => {
  const navigate = useNavigate();
  const { elementClickedEvent } = useTracking();
  const { ConfirmDialog, confirm } = useConfirmDialog();
  const handleClick = async () => {
    elementClickedEvent({
      elementType: 'button',
      name: 'favoritar',
      location: window.location.pathname,
      text: `Encontrar meu cartão ideal`,
    });

    const wasMatchMakerData = await getFromStorage<MatchMakerCache>(StorageMap.MatchMaker);
    if (wasMatchMakerData?.step_name === 'annuity') {
      const result = await confirm({
        title: 'Match Positivo',
        message: 'Você gostaria de refazer o quiz para ver novos resultados de cartões de crédito ou ver seu último resultado?',
        buttonTrue: 'Refazer quiz',
        buttonFalse: 'Ver resultado anterior',
      });

      if (result) {
        removeFromStorage(StorageMap.MatchMaker);
      }

      elementClickedEvent({
        elementType: 'button',
        name: 'match-maker-clean',
        location: window.location.pathname,
        text: result ? 'Refazer quiz' : 'Ver resultado anterior',
      });
    }
    navigate(RouteMap.MatchMakerResult);
  };
  return (
    <>
      <div className="favorite-empty">
        <div className="favorite-empty__img">
          <img src={CreditCardIcon} alt="desenho de um cartão de crédito" />
        </div>
        <div className="favorite-empty__texts">
          <Text as="h2" variant="heading-micro" weight="bold">
            Você ainda não tem cartões favoritos
          </Text>
          <Text as="p" variant="body-small">
            Encontre seu cartão ideal, confira as melhores opções de{' '}
            <strong>cashback e milhas!</strong>
          </Text>
        </div>
        <div className="favorite-empty__button">
          <Button expand="x" onClick={handleClick}>
            Encontrar meu cartão ideal
          </Button>
        </div>
      </div>
      {createPortal(ConfirmDialog, document.body)}
    </>
  );
};
