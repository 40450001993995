import classNames from 'classnames';
import { Conditional } from 'iq-blueberry';

import '@glidejs/glide/dist/css/glide.theme.min.css';
import '@glidejs/glide/dist/css/glide.core.min.css';

import './styles.scss';
import { useCarousel } from './hook';

export interface ICarouselProps {
  carouselId: string;
  sliderConfig?: Partial<Glide.Options>;
  bulletsHide?: boolean;
  bulletsPosition?: 'top' | 'bottom';
  stepChangeFunction?: (e?: any) => void;
  clickNavigation?: boolean;
  children: React.ReactNode;
}
export const Carousel: React.FC<ICarouselProps> = (props) => {
  const { childrenList, bulletsHide, bulletsPosition, carouselId } =
    useCarousel(props);

  const carouselClass = classNames(['glide', 'carousel', carouselId], {
    'carousel--bullets-top': bulletsPosition === 'top',
    'carousel--bullets-hide': bulletsHide,
  });

  const bulletVariantClass = classNames(
    ['glide__bullets', 'carousel__bullets'],
    {
      'carousel__bullets--top': bulletsPosition === 'top',
    }
  );

  return (
    <div className={carouselClass}>
      <div className="glide__track carousel__container" data-glide-el="track">
        <ul className="glide__slides">
          {childrenList.map((item, i) => (
            <li
              className="glide__slide carousel__slides"
              key={`${carouselId}-item-${i}`}
              data-glide-el={`=${i}`}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>

      <Conditional
        condition={!!bulletsHide}
        renderIf={null}
        renderElse={
          <div className={bulletVariantClass} data-glide-el="controls[nav]">
            {childrenList.map((_, i) => (
              <button
                data-glide-dir={`=${i}`}
                key={`${carouselId}-navigation-${i}`}
              />
            ))}
          </div>
        }
      />
    </div>
  );
};
