import './styles.scss';
import infoCircle from '../../assets/icon/InfoCircle.svg';
import billBarCode from '../../assets/icon/billBarCode.svg';
import { Text } from 'iq-blueberry';

interface EmptyCardProps {
  setIsModalOpen: () => void;
}

export const EmptyCard: React.FC<EmptyCardProps> = ({ setIsModalOpen }) => {
  return (
    <div className="empty-card">
      <img
        className="empty-card__logo"
        src={billBarCode}
        alt="Icone código de barras"
      />
      <img
        className="empty-card__icon"
        src={infoCircle}
        alt="Icone de um circulo com um I ao centro representando informação."
        onClick={() => setIsModalOpen()}
      />
      <Text
        className="empty-card__text"
        as="p"
        variant="body-medium"
        variantDesk="body-medium"
      >
        Você não tem dívidas na <strong>Acordo Certo</strong> para negociar
      </Text>
    </div>
  );
};
