export const useSkeletonCard = () => {
  const variants = {
    initialPoolingText: {
      firstPart: 'Estamos verificando se você tem alguma dívida para ',
      strongPart: 'negociar na Acordo Certo...',
    },

    continuePollingText: {
      firstPart: 'Ainda estamos verificando se você tem alguma dívida para ',
      strongPart: 'negociar na Acordo Certo...',
    },
  };

  return variants;
};
