import { Children, useEffect } from 'react';
import Glide from '@glidejs/glide';
import { delay } from '@libs/utils/helpers/delay';
import { ICarouselProps } from '.';

export const useCarousel = ({
  carouselId,
  sliderConfig,
  children,
  bulletsHide,
  bulletsPosition,
  stepChangeFunction,
  clickNavigation,
}: ICarouselProps) => {
  const childrenList = Children.toArray(children);
  const defaultConfig: Partial<Glide.Options> = {
    type: 'slider',
    dragThreshold: 120,
    rewind: false,
    breakpoints: {
      768: {
        peek: { before: 0, after: 60 },
        perView: 1,
        type: 'carousel',
      },
    },
  };

  const safeConfig = {
    ...defaultConfig,
    ...sliderConfig,
  };

  const slider = new Glide(`.${carouselId}`, safeConfig);

  useEffect(() => {
    // wait the DOM to calculate correctly the width
    delay(100).then(() => {
      if(!slider) return;
      slider.mount();
      slider.on('run.after', handleChange);

      const slides = document?.querySelectorAll('.glide__slides') || [];

      if (clickNavigation) {
        slides?.forEach((item) =>
          item.addEventListener('click', handleClickNavigation)
        );
      }

      return () => {
        slides?.forEach((item) =>
          item.removeEventListener('click', handleClickNavigation)
        );
      };
    });
  }, []);

  useEffect(() => {
    delay(100).then(() => {
      slider.update();
    });
  }, [sliderConfig, carouselId, bulletsHide, bulletsPosition]);

  function handleChange(e?: any) {
    if (!stepChangeFunction) return;
    stepChangeFunction(e);
  }

  function handleClickNavigation(e: any) {
    const index = parseInt(e.target.getAttribute('data-index'));
    if (index === 0 || index > 0) {
      slider.go('=' + index);
    }
  }

  return {
    childrenList,
    carouselId,
    bulletsHide,
    bulletsPosition,
  };
};
