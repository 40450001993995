import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScoreTipInterface, scoreTips } from './data';
import { useTracking } from '@common/hooks/useTracking';

export const useScoreTip = () => {
  const [currentTip, setCurrentTip] = useState<ScoreTipInterface>(scoreTips[0]);

  const { elementClickedEvent } = useTracking();
  const navigate = useNavigate();

  const getCurrentWeek = (): number => {
    const startDate = new Date('2024-08-12');
    const today = new Date();
    const diffInDays = Math.floor(
      (today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    return (Math.floor(diffInDays / 7) % scoreTips.length) + 1;
  };

  useEffect(() => {
    const week = getCurrentWeek();
    const tip = scoreTips.find((tip) => tip.week === week);
    if (tip) setCurrentTip(tip);
  }, []);

  const handleGoTo = () => {
    elementClickedEvent({
      elementType: 'card',
      location: 'Score Tips',
      name: `Score tip - Week ${currentTip.week}`,
      text: currentTip.textButton,
    });

    navigate(currentTip.goTo);
  };

  return { currentTip, handleGoTo };
};
