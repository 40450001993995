import { getApp } from './getApplication';
import { handleDateFormat } from './handleDate';

type AutofillData = {
  url: string;
  productSlug: string;
  user: FireboltUserInfo;
};

export type FireboltUserInfo = {
  cpf?: string;
  fullName?: string;
  email?: string;
  phone?: string;
  birthdate?: string;
};

export const addAutofillParam = ({ url, productSlug, user }: AutofillData) => {
  const formattedBirthdate =
    user?.birthdate && handleDateFormat(user?.birthdate);
  const application =
    getApp() === 'consumidorpositivo'
      ? 'consumidorpositivo-log'
      : 'acordocerto';

  const payload = {
    productSlug: productSlug,
    application,
    cpf: user?.cpf,
    full_name: user?.fullName,
    email: user?.email,
    main_phone: user?.phone,
    birthdate: formattedBirthdate || '',
  };

  const encodedAutofill = encodeURIComponent(JSON.stringify(payload));
  const encryptedAutofill = window.btoa(encodedAutofill);
  const concatChar = url.includes('?') ? '&' : '?';
  return `${url}${concatChar}autofill=${encryptedAutofill}`;
};
