import iconSearchCP from '@tally/assets/icons/filled-search-cp.svg';
import iconBillMoneyCP from '@tally/assets/icons/filled-bill-money-cp.svg';
import iconBillFoldCP from '@tally/assets/icons/filled-bill-fold-cp.svg';

const data = {
  cards: [
    {
      icon: iconSearchCP,
      iconAlt: `Ícone de uma Lupa Azul`,
      text: `Tenha poucas consultas em seu CPF nos últimos 30 dias.`,
    },
    {
      icon: iconBillMoneyCP,
      iconAlt: `ícone de uma conta com uma cédula de dinheiro em cima `,
      text: `Pague suas dívidas em dia e o valor total delas.`,
    },
    {
      icon: iconBillFoldCP,
      iconAlt: `ícone de uma conta enrolada`,
      text: `Não tenha dívidas atrasadas em seu nome.`,
    },
  ],
};

export const content = data;
