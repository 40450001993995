import { useEffect, useState } from 'react';

import { delay } from '@libs/utils/helpers/delay';
import { getPartnerImage } from '@libs/utils/helpers/getPartnerImage';
import { currencyToLocale } from '@libs/utils/helpers/currencyToLocale';

import useApiAuth from '@admission/apis/auth/useApiAuth';

import { useTracking } from '@common/hooks/useTracking';
import { StorageMap } from '@common/constants/StorageMap';
import { handleErrorLog } from '@common/utils/handleErrorLogs';
import { getFromStorage, setOnStorage } from '@common/utils/handleStorage';
import {
  addMinutesToCurrentTimestamp,
  isTimestampExpire,
} from '@common/utils/handleCache';

import {
  DebtResume,
  PartnerSystems,
  SortingDebtsCache,
} from '@dues/types/sorting-debt';

type UseSortingDebts = {
  storageToSave?: 'session' | 'local';
  retryIfPartial?: boolean;
  maxTries?: number;
  intervalInSeconds?: number;
  maxErrorTries?: number;
};
export const useSortingDebts = (props?: UseSortingDebts) => {
  const intervalInSeconds = props?.intervalInSeconds || 2;
  const maxTries = props?.maxTries || 30;
  const maxErrorTries = props?.maxErrorTries || 5;
  const retryIfPartial = props?.retryIfPartial || false;
  const storageToSave = props?.storageToSave || 'session';
  const CACHE_IN_MINUTES = 15;

  const { apiAuthCustomerDebts } = useApiAuth();
  const { debtLocated } = useTracking();

  const [sortingDebtList, setSortingDebtList] = useState<DebtResume[]>([]);
  const [sortingDebtComplete, setSortingDebtComplete] = useState(false);
  const [sortingDebtLoading, setSortingDebtLoading] = useState(true);
  const [sortingDebtError, setSortingDebtError] = useState(false);

  useEffect(() => {
    handleUserDebts();
  }, []);

  async function handleUserDebts() {
    setSortingDebtLoading(true);
    try {
      const cacheDebts = getFromStorage<SortingDebtsCache>(
        StorageMap.SortingDebts
      );

      const hasDebtsOnCache = cacheDebts && cacheDebts?.resumeDebts?.length > 0;
      const isExpiredCache = cacheDebts && isTimestampExpire(cacheDebts.expire);
      if (
        hasDebtsOnCache &&
        !isExpiredCache &&
        (!retryIfPartial || cacheDebts?.isComplete)
      ) {
        setSortingDebtList(cacheDebts.resumeDebts);
        return setSortingDebtComplete(cacheDebts?.isComplete);
      }

      await poolingRequest(maxTries, intervalInSeconds);
    } catch (error) {
      handleErrorLog(error);
      setSortingDebtError(true);
    } finally {
      setSortingDebtLoading(false);
    }
  }

  async function poolingRequest(maxTries: number, delayInSeconds: number) {
    let previousLength = 0;
    let error = null;
    let errorTries = 0;
    for (let attempt = 0; attempt <= maxTries; attempt++) {
      try {
        if (attempt > 0) await delay(delayInSeconds * 1500);
        const response = await apiAuthCustomerDebts.send();
        const debts = response?.data?.partnerSystems || [];
        const isComplete = response?.data?.status === 'COMPLETED';
        if (isComplete) return handleSaveData(debts, isComplete);

        if (debts.length > previousLength) {
          handleSaveData(debts, isComplete);
          previousLength = debts.length;
          attempt = 0;
          errorTries = 0;
        }

        if (attempt === maxTries) return handleSaveData(debts, isComplete);
      } catch (err) {
        error = err;
        errorTries += 1;
        if (errorTries >= maxErrorTries) {
          throw new Error('Max error tries reached: ' + maxErrorTries);
        }
      }
    }

    if (error !== null) throw error;
  }

  function handleSaveData(data: PartnerSystems[], isComplete: boolean) {
    const formattedData = formatData(data, isComplete);
    setOnStorage(storageToSave, StorageMap.SortingDebts, formattedData);
    setSortingDebtList(formattedData.resumeDebts);
    setSortingDebtComplete(isComplete);
  }

  function formatData(
    data: PartnerSystems[],
    isComplete: boolean
  ): SortingDebtsCache {
    if (data?.length === 0)
      return {
        isComplete,
        resumeDebts: [],
        completeDebts: [],
        expire: 0,
      };

    const onlyPartnersWithDebt = data.filter(
      (x) => x.show && x.debts.length > 0
    );

    const formattedDebts: DebtResume[] = [];
    for (const partner of onlyPartnersWithDebt) {
      for (const debt of partner.debts) {
        const payment = debt.paymentOptions[0];
        debtLocated(partner);

        const resume: DebtResume = {
          id: debt.id,
          partnerId: partner.id,
          partnerName: partner.name,
          productName: debt?.product,
          source: debt?.source,
          partnerMessage: partner.promoMessage || '-',
          contract: debt?.contract,
          debtValue: currencyToLocale(payment.currentDebtValue),
          agreementValue: currencyToLocale(payment.agreementValue),
          discountValue: Math.floor((payment?.discountPercentage || 0) * 100),
          hasBvPartnership: partner?.hasBvsPartnership,
          debtsIsNegative: debt.debtIsNegative,
          isComplete,
          image: {
            src: getPartnerImage(partner.identifier),
            alt: `Logo ${partner.name}`,
          },
          redirectACData: {
            path: 'confirmacao',
            identifier: partner?.identifier,
            contract: debt?.contract,
            debtId: debt?.id,
            agreementValue: payment?.agreementValue,
          },
          redirectDebtsData: {
            identifier: partner?.identifier,
            partnerId: partner?.id,
            partnerName: partner?.partnerIdentifier,
          },
        };

        formattedDebts.push(resume);
      }
    }

    return {
      isComplete,
      resumeDebts: formattedDebts,
      completeDebts: data,
      expire: addMinutesToCurrentTimestamp(CACHE_IN_MINUTES),
    };
  }

  return {
    sortingDebtList,
    sortingDebtComplete,
    sortingDebtLoading,
    sortingDebtError,
  };
};
