import { Text, IconX, IconInfo } from '@consumidor-positivo/aurora';

import infoBox from '@common/assets/icon/info-circle-purple.svg';
import './styles.scss';
import React from 'react';

interface InfoCardProps {
  infoText: string | React.ReactNode;
  buttonText?: string;
  handleAction?: () => void;
  handleClose?: () => void;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  infoText,
  buttonText,
  handleAction,
  handleClose,
}) => {
  return (
    <div className="info-card">
      <div className="info-card__icon">
        <img src={infoBox} alt="Icone de informação" />
      </div>
      <div className="info-card__content">
        <Text as="p" variant="body-medium">
          {infoText}
          {!!buttonText && (
            <button className="info-card__button" onClick={handleAction}>
              {` ${buttonText}`}
            </button>
          )}
        </Text>
      </div>
      {!!handleClose && (
        <div className="info-card__close" onClick={handleClose}>
          <IconX />
        </div>
      )}
    </div>
  );
};
