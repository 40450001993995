import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { RouteMap } from '@common/constants/RouteMap';
import Dashboard from '@common/pages/Dashboard';

const CpfLookout = lazy(() => import('../pages/CpfLookout'));
const NegativeDebts = lazy(() => import('../pages/NegativeDebts'));
const BillsAndPayments = lazy(() => import('../pages/BillsAndPayments'));

const ProductList = lazy(() => import('../pages/ProductList'));
const ProductDetail = lazy(() => import('../pages/ProductDetail'));
const InstallmentDetail = lazy(() => import('../pages/InstallmentDetail'));

export function useBillsRouter() {
  return (
    <>
      <Route path={RouteMap.CpfLookout} element={<CpfLookout />} />
      <Route path={RouteMap.NegativeDebts} element={<NegativeDebts />} />
      {/* <Route path={RouteMap.BillsAndPayments} element={<BillsAndPayments />} /> */}
      <Route path={RouteMap.BillsAndPayments} element={<Dashboard />} />
      <Route
        path={`${RouteMap.BillsProductList}/:categorySlug`}
        element={<ProductList />}
      />
      <Route
        path={`${RouteMap.BillsProductDetail}/:categorySlug/:productId`}
        element={<ProductDetail />}
      />
      <Route
        path={`${RouteMap.BillsInstallmentDetail}/:categorySlug/:productId/:installmentId`}
        element={<InstallmentDetail />}
      />
    </>
  );
}
