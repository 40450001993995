import './styles.scss';
import { useEffect } from 'react';
import classnames from 'classnames';
import { Button, IconSend, Text } from '@consumidor-positivo/aurora';
import React from 'react';

interface ConfirmDialogProps {
  title?: string;
  message?: string;
  buttonFalse?: string;
  buttonTrue?: string;
  iconButtonFalse?: React.ReactNode;
  iconButtonTrue?: React.ReactNode;
  showConfirmDialog: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title = 'Confirmação',
  message = 'Tem certeza desta ação?',
  buttonFalse = 'Cancelar',
  buttonTrue = 'Confirmar',
  iconButtonFalse,
  iconButtonTrue,
  showConfirmDialog,
  onConfirm,
  onCancel,
}) => {
  useEffect(() => {
    document.body.style.overflow = showConfirmDialog ? 'hidden' : 'auto';
  }, [showConfirmDialog]);

  return (
    <div
      className={classnames('confirm-dialog', {
        'confirm-dialog-open': showConfirmDialog,
        'confirm-dialog-close': !showConfirmDialog,
      })}
      id="confirm-dialog"
    >
      <div
        className={classnames('confirm-dialog__card', {
          'confirm-dialog-in': showConfirmDialog,
          'confirm-dialog-out': !showConfirmDialog,
        })}
        id="card"
      >
        <div className="confirm-dialog__header">
          <Text
            as="h5"
            variant="heading-small"
            variantDesk="heading-medium"
            weight="bold"
          >
            {title}
          </Text>
        </div>
        <div className="confirm-dialog__content">
          <Text as="p" variant="body-medium" variantDesk="body-medium">
            {message}
          </Text>
        </div>
        <div className="confirm-dialog__footer">
          <Button
            className="confirm-dialog__button"
            type="outlined"
            onClick={onCancel}
          >
            {iconButtonFalse && <span>{iconButtonFalse}</span>}
            {buttonFalse}
          </Button>
          <Button
            className="confirm-dialog__button"
            type="primary"
            onClick={onConfirm}
          >
            {iconButtonTrue && <span>{iconButtonTrue}</span>}
            {buttonTrue}
          </Button>
        </div>
      </div>
    </div>
  );
};
