import { useState, useEffect } from 'react';
import { useDeviceSize } from '@libs/utils/hooks/useDeviceSize';

import { useTracking } from '@common/hooks/useTracking';

export const useMonitoringStatus = () => {
  const [statusMonitoring, setStatusMonitoring] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { elementClickedEvent } = useTracking();
  const { isMobile } = useDeviceSize();

  function getPushNotificationStatus() {
    return new Promise<boolean>((resolve) => {
      const hasPushNotificationStatus =
        'isPushNotificationEnabled' in window.NativeSettings!;
      if (hasPushNotificationStatus)
        return resolve(!!window.NativeSettings?.isPushNotificationEnabled);

      const interval = setInterval(() => {
        if (!hasPushNotificationStatus) return;

        clearInterval(interval);
        resolve(!!window.NativeSettings?.isPushNotificationEnabled);
      }, 1000);
    });
  }

  const checkMonitoringStatus = async () => {
    if (!window.NativeFeatures?.PushNotificationStatus) return false;

    return getPushNotificationStatus();
  };

  useEffect(() => {
    (async () => {
      const status = await checkMonitoringStatus();
      setStatusMonitoring(status);
    })();
  }, []);

  const handleStoreRedirect = () => {
    const isIOS = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);
    const redirectURL = 'https://consumidorpositivo.onelink.me/3ote/ty1v44fw';

    elementClickedEvent({
      elementType: 'button/text',
      text: `Redirecionado para ${isIOS ? 'Apple Store' : 'Google Play'}`,
      location: 'Dashboard - Meu Score',
    });

    window.open(redirectURL, '_top');
  };

  const handleMonitoringStatusClick = () => {
    if (!window.isNativeApp) {
      setIsModalOpen(true);

      return elementClickedEvent({
        elementType: 'button',
        name: 'monitoring-status',
        text: 'is-not-native-app',
        location: 'home-meu-score',
      });
    }

    elementClickedEvent({
      elementType: 'button',
      name: 'monitoring-status',
      text: 'is-push-disabled',
      location: 'home-meu-score',
    });

    const message = { action: 'openNotificationSettings' };
    window.ReactNativeWebView.postMessage(JSON.stringify(message));

    setStatusMonitoring(true);
  };

  return {
    handleMonitoringStatusClick,
    handleStoreRedirect,
    isModalOpen,
    setIsModalOpen,
    statusMonitoring,
    isMobile,
  };
};
