import { useEffect, useRef, useState } from 'react';

import { delay } from '@libs/utils/helpers/delay';

import { RouteMap } from '@common/constants/RouteMap';
import { useTracking } from '@common/hooks/useTracking';
import { useUserContext } from '@common/contexts/UserContext';

import { DebtsCardProps } from '.';
import { useACRedirect } from '@dues/hooks/useACRedirect';

export const useDebtsCard = ({ debt, position }: DebtsCardProps) => {
  const debtRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingRedirect, setLoadingRedirect] = useState(false);

  const { debtClicked, debtViewed, elementClickedEvent } = useTracking();

  const { user } = useUserContext();

  const { sendProposalStartedAndRedirectToAC } = useACRedirect();

  useEffect(() => {
    const observer = new IntersectionObserver(async (entries, observer) => {
      if (entries[0].isIntersecting) {
        await delay(150);
        debtViewed(debt, position);
        if (debtRef.current) observer.unobserve(debtRef.current);
      }
    });

    if (debtRef.current) observer.observe(debtRef.current);
    return () => {
      if (debtRef.current) observer.unobserve(debtRef.current);
    };
  }, [debtRef]);

  const handleNegotiate = async () => {
    if (!user?.document) {
      throw new Error('user not found');
    }

    setLoadingRedirect(true);

    debtClicked(debt, position);
    await delay(150);

    const params = `utm_source=cross-sell&utm_medium=consumidorpositivo&utm_campaign=pendencia`;
    sendProposalStartedAndRedirectToAC(
      params,
      debt.redirectACData,
      debt.redirectDebtsData
    ).finally(() => setLoadingRedirect(false));
  };

  const toggleModal = () => {
    const modalStatus = isModalOpen ? 'fechar' : 'abrir';
    elementClickedEvent({
      elementType: 'button/icon',
      location: RouteMap.MyDebts,
      text: `${modalStatus} modal divida negativada`,
    });

    setIsModalOpen(!isModalOpen);
  };

  return {
    debtRef,
    isModalOpen,
    handleNegotiate,
    toggleModal,
    loadingRedirect,
  };
};
