import classNames from 'classnames';
import { currencyToLocale } from '@libs/utils/helpers/currencyToLocale';
import './styles.scss';
import { Conditional } from '@consumidor-positivo/aurora';
import fireEmoji from '@dues/assets/fire-emoji.svg';

type HubTagProps = {
  isPreApproved?: boolean;
  limit?: number;
  featureInformation?: string;
};

export const HubTag: React.FC<HubTagProps> = ({
  isPreApproved,
  limit,
  featureInformation
}) => {

  const preApprovedOrLimitText = (isPreApproved || Boolean(limit) )&& '🔥 Chance alta de aprovação';

  const text = preApprovedOrLimitText || featureInformation;

  const tagClass = classNames('offer-card-tag', {
    'offer-card-tag--preApproved': isPreApproved || limit,
    'offer-card-tag--pattern': !isPreApproved && featureInformation && !limit,
  });

  if (!text) return null;

  return (
    <div className={tagClass}>
      <p className="offer-card-tag__info">{text}</p>
    </div>
  );
};
