import { useScoreTip } from './hook';
import { Text } from 'iq-blueberry';

import externaLinkIcon from '@common/assets/icon/external-link.svg';
import assistantIcon from '@common/assets/icon/assistant.svg';

import './styles.scss';

export const ScoreTipsBanner: React.FC = () => {
  const { currentTip, handleGoTo } = useScoreTip();

  return (
    <div className="score-tips">
      <div className="score-tips__banner">
        <div className="score-tips__content">
          <div className="score-tips__text">
            <div className="score-tips__title">
              <Text as="p" variant="body-small" variantDesk="body-large">
                Dicas do Score | <strong>{currentTip.name}</strong>
              </Text>
            </div>
            <div className="score-tips__paragraph">
              <p
                className="text-body-small"
                dangerouslySetInnerHTML={{ __html: currentTip.tip }}
              />
            </div>
          </div>

          <div className="score-tips__icon-assistant">
            <img src={assistantIcon} alt="Assistente do Score" />
          </div>
        </div>

        <div className="score-tips__show-more">
          <img src={externaLinkIcon} alt="Imagem com seta de direcionamento" />
          <button onClick={handleGoTo} className="score-tips__button">
            {currentTip.textButton}
          </button>
        </div>
      </div>

      <div className="score-tips__calendar">
        <Text as="p" variant="body-small" variantDesk="body-medium">
          <span> 📆 </span> Toda semana uma dica diferente!
        </Text>
      </div>
    </div>
  );
};
