import classNames from 'classnames';
import { AppStoresModal } from '../AppStoresModal';
import { useAppStoresBanner } from './hook';

import googlePlayBadge from '../../assets/images/google-play-badge.webp';
import appStoreBadge from '../../assets/images/app-store-badge.webp';

import './styles.scss';

type AppStoresBannerProps = {
  minimal?: boolean;
};
export const AppStoresBanner: React.FC<AppStoresBannerProps> = ({
  minimal = false,
}) => {
  const { displayModal, setDisplayModal, handleStoreRedirect } =
    useAppStoresBanner();

  const bannerClass = classNames(
    minimal ? 'app-stores-banner-minimal' : 'app-stores-banner'
  );
  return (
    <>
      <div className={bannerClass} onClick={handleStoreRedirect}>
        <h2 className={`${bannerClass}__title`}>
          Baixe <strong>grátis</strong> nosso app e veja como{' '}
          <span className={`${bannerClass}__title--text-green`}>subir</span> o{' '}
          <strong>Score Positivo</strong>
        </h2>
        <div className={`${bannerClass}__stores`}>
          <img src={googlePlayBadge} alt="" />
          <img src={appStoreBadge} alt="" />
        </div>
      </div>

      <AppStoresModal
        showModal={displayModal}
        onClose={() => setDisplayModal(false)}
      />
    </>
  );
};
