import { useEffect, useState } from 'react';

import { useSortingDebts } from '@dues/hooks/useSortingDebt';
import { useTracking } from '@common/hooks/useTracking';

import { delay } from '@libs/utils/helpers/delay';
import { useACRedirect } from '@dues/hooks/useACRedirect';
import { DebtResume } from '@dues/types/sorting-debt';

type DebtsList = {
  isLoading: boolean;
  isEmpty: boolean;
  item: DebtResume;
};

export const useMyDebts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [debtsList, setDebtsList] = useState<DebtsList[]>([
    {
      isLoading: true,
      isEmpty: false,
      item: {} as DebtResume,
    },
  ]);

  const { elementClickedEvent } = useTracking();

  const {
    sortingDebtError,
    sortingDebtList,
    sortingDebtLoading,
    sortingDebtComplete,
  } = useSortingDebts();

  const { sendProposalStartedAndRedirectToAC } = useACRedirect();

  useEffect(() => {
    itemsToRender(sortingDebtList, sortingDebtComplete);
  }, [sortingDebtList, sortingDebtComplete]);

  const handleNegotiate = async () => {
    elementClickedEvent({
      elementType: 'button',
      text: `Tab Meu CPF - Negociar na Acordo Certo`,
    });
    await delay(150);

    const params =
      'utm_source=cross-sell&utm_medium=consumidorpositivo&utm_campaign=pendencia';
    sendProposalStartedAndRedirectToAC(params);
  };

  const itemsToRender = (list: DebtResume[], isComplete: boolean) => {
    const formattedList: DebtsList[] = [];

    if (list.length > 0) {
      for (const item of list) {
        formattedList.push({ item, isLoading: false, isEmpty: false });
      }
    }

    if (!isComplete) {
      formattedList.push({
        isLoading: true,
        isEmpty: false,
        item: {} as DebtResume,
      });
    }

    setDebtsList(formattedList);
  };

  return {
    sortingDebtLoading,
    sortingDebtError,
    debtsList,
    sortingDebtComplete,
    isModalOpen,
    setIsModalOpen,
    handleNegotiate,
  };
};
