import './styles.scss';
import { Modal } from '@common/components/Modal';
import locoAcCp from '../../assets/logo-cp-ac.svg';
import { Button, Text } from 'iq-blueberry';

interface AcModalProps {
  isModalOpen: boolean;
  setIsModalOpen: () => void;
  handleNegotiate: () => void;
}

export const AcModal: React.FC<AcModalProps> = ({
  setIsModalOpen,
  isModalOpen,
  handleNegotiate,
}) => {
  return (
    <Modal
      showModal={isModalOpen}
      mobile="center"
      desktop="center"
      onClose={setIsModalOpen}
    >
      <div className="ac-modal">
        <img
          className="ac-modal__logo"
          src={locoAcCp}
          alt="Logo da empresa Consumidor positivo mais a Acordo Certo"
        />
        <Text as="h2" variant="heading-five" variantDesk="heading-three">
          Acordo Certo é uma empresa Consumidor Positivo
        </Text>
        <Text
          as="p"
          className="ac-modal__text"
          variant="body-medium"
          variantDesk="body-large"
        >
          Acordo Certo é uma empresa de negociação de dívidas online, e
          trabalhamos em conjunto para oferecer para você as melhores ofertas
          para quitar suas dívidas.
        </Text>
        <Text
          as="p"
          className="ac-modal__text"
          variant="body-medium"
          variantDesk="body-large"
        >
          Você pode conhecer o site da Acordo Certo agora{' '}
          <span onClick={handleNegotiate} className="ac-modal__link-button">
            clicando aqui.
          </span>
        </Text>
        <div className="ac-modal__back-button">
          <Button
            type="primary"
            color="default"
            loading={false}
            expand="xy"
            size="medium"
            justify="center"
            disabled={false}
            onClick={setIsModalOpen}
          >
            Voltar
          </Button>
        </div>
      </div>
    </Modal>
  );
};
