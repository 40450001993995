import { useEffect } from 'react';
import { Text } from 'iq-blueberry';
import { useLocation } from 'react-router-dom';

import { Container } from '@common/components/Container';
import { CardIcon } from '@common/components/CardIcon';
import { Carousel } from '@common/components/Carousel';
import { useTracking } from '@common/hooks/useTracking';

import noDataImg from '@tally/assets/illustrations/no-data-consumer.webp';

import './styles.scss';
import { content } from './data';

export const NegativeConsumer: React.FC = () => {
  const carouselConfig: Partial<Glide.Options> = {
    dragThreshold: 120,
    rewind: false,
    perView: 1,
    gap: 16,
    breakpoints: {
      768: {
        peek: { before: 20, after: 60 },
        type: 'slider',
      },
    },
  };

  const { elementViewedEvent } = useTracking();
  const location = useLocation();

  useEffect(() => {
    elementViewedEvent({
      elementType: 'content',
      location: location.pathname,
      name: 'negative-user',
    });
  }, []);

  return (
    <section className="tally-negative-consumer">
      <Container>
        <div className="tally-negative-consumer__content">
          <img
            className="tally-negative-consumer__content__image"
            src={noDataImg}
            alt="Ilustração de uma pessoa triste olhando para uma página com um ponto de interrogação"
            width={170}
            height={200}
          />
          <div className="tally-negative-consumer__content__texts">
            <div className="tally-negative-consumer__content__texts__title">
              <Text
                as="h2"
                variant="heading-six"
                variantDesk="heading-four"
                defaultColor="title"
              >
                Não é possível analisar seus hábitos financeiros
              </Text>

              <Text as="p" variant="body-micro" variantDesk="body-medium">
                Para mostrar dicas e Objetivos personalizados, precisamos de
                mais informações sobre seus hábitos financeiros.
              </Text>
            </div>

            <div className="tally-negative-consumer__content__texts__detail">
              <Text
                as="h3"
                variant="body-micro"
                variantDesk="body-medium"
                defaultColor="title"
              >
                <strong>
                  Você ainda pode melhorar sua pontuação seguindo algumas dicas
                  básicas:
                </strong>
              </Text>
              <div className="tally-negative-consumer__content__texts__detail__carousel only-desk">
                {content.cards.map((item, index) => (
                  <CardIcon
                    icon={item.icon}
                    iconAlt={item.iconAlt}
                    text={item.text}
                    reverse
                    key={`card-desk-${index}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="tally-negative-consumer__carousel only-mob">
        <Carousel carouselId="negative-consumer" sliderConfig={carouselConfig}>
          {content.cards.map((item, index) => (
            <CardIcon
              icon={item.icon}
              iconAlt={item.iconAlt}
              text={item.text}
              reverse
              key={`card-carousel-${index}`}
            />
          ))}
        </Carousel>
      </div>
    </section>
  );
};
