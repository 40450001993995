import { RouteMap } from '@common/constants/RouteMap';

export interface ScoreTipInterface {
  week: number;
  name: string;
  tip: string;
  textButton: string;
  goTo: string;
}

export const scoreTips: ScoreTipInterface[] = [
  {
    week: 1,
    name: 'Consultas',
    tip: '<strong>Consultar o próprio CPF não diminui seu Score,</strong> apenas consultas feitas por empresa ou bancos.',
    textButton: 'Ver consultas em meu CPF',
    goTo: RouteMap.CpfLookout,
  },
  {
    week: 2,
    name: 'Negativação',
    tip: 'Dívidas negativadas são contas atrasadas que foram colocadas no <strong>cadastro de inadimplência da Boa Vista.</strong>',
    textButton: 'Ver minhas negativações',
    goTo: RouteMap.NegativeDebts,
  },
  {
    week: 3,
    name: 'Cartão de crédito',
    tip: '<strong>Pagar em dia e o valor total das suas faturas,</strong> faz você ter um histórico de pagamento positivo.',
    textButton: 'Ver contas e pagamentos',
    goTo: RouteMap.BillsAndPayments,
  },
  {
    week: 4,
    name: 'Consultas',
    tip: '<strong>Ter muitas consultas em seu CPF faz seu Score cair,</strong> pois parece que você está atrás de crédito sem cuidado.',
    textButton: 'Ver consultas em meu CPF',
    goTo: RouteMap.CpfLookout,
  },
  {
    week: 5,
    name: 'Negativação',
    tip: 'Após quitar uma dívida negativa, <strong>você precisa aguardar 6 meses</strong> a partir da data de pagamento para seu Score subir.',
    textButton: 'Ver minhas negativações',
    goTo: RouteMap.NegativeDebts,
  },
  {
    week: 6,
    name: 'Cartão de crédito',
    tip: '<strong>Você precisa de um cartão de crédito</strong> para ter um histórico de pagamento para o Score analisar se você é um bom pagador.',
    textButton: 'Ver ofertas de crédito',
    goTo: RouteMap.Offer,
  },
  {
    week: 7,
    name: 'Consultas',
    tip: '<strong>Bancos e empresas consultam seu CPF quando você busca:</strong> cartão de crédito, crediário, consórcio ou financiamento.',
    textButton: 'Ver consultas em meu CPF',
    goTo: RouteMap.CpfLookout,
  },
  {
    week: 8,
    name: 'Cartão de crédito',
    tip: 'Você pode construir seus histórico de pagamento com um <strong>cartão de crédito com limite pequeno,</strong> o importante é fazer o pagamento das faturas em dia.',
    textButton: 'Ver ofertas de crédito',
    goTo: RouteMap.Offer,
  },
  {
    week: 9,
    name: 'Consultas',
    tip: '<strong>Para diminuir o número de consultas,</strong> evite buscar crédito por pelo menos 6 meses.',
    textButton: 'Ver consultas em meu CPF',
    goTo: RouteMap.CpfLookout,
  },
];
