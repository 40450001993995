import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTracking } from '@common/hooks/useTracking';
import { useDeviceSize } from '@libs/utils/hooks/useDeviceSize';

export const useAppStoresBanner = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const { elementClickedEvent } = useTracking();
  const { isMobile } = useDeviceSize();
  const { pathname } = useLocation();

  const handleStoreRedirect = () => {
    const isIOS = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);
    const redirectURL = 'https://consumidorpositivo.onelink.me/3ote/yubi3yrx';

    if (isMobile) {
      elementClickedEvent({
        elementType: 'link/image',
        text: `ASB Mobile: ${isIOS ? 'Apple Store' : 'Google Play'}`,
        location: pathname,
      });

      window.open(redirectURL, '_top');
    } else {
      elementClickedEvent({
        elementType: 'button',
        text: `ASB Desktop: Redirect para as lojas`,
        location: pathname,
      });

      setDisplayModal(true);
    }
  };

  return {
    displayModal,
    setDisplayModal,
    handleStoreRedirect,
  };
};
