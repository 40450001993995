/* eslint-disable @typescript-eslint/no-non-null-assertion */
import classNames from 'classnames';
import { Conditional, Text } from 'iq-blueberry';

import chevronBlue from '@common/assets/icon/chevron-right-cp.svg';
import giftBox from '@common/assets/icon/gift-box.svg';

import './styles.scss';
export interface ICardCallToActionProps {
  title: string;
  icon: string;
  iconAlt: string;
  iconSize?: number;
  description?: string;
  tag?: boolean;
  tagText?: string;
  compact?: boolean;
  horizontalCard?: boolean;
  mobileColumnDirection?: boolean;
  onClick?: (title: string, description?: string) => void;
}
export const CardCallToAction: React.FC<ICardCallToActionProps> = ({
  title,
  icon,
  iconAlt,
  iconSize,
  tag,
  tagText,
  horizontalCard,
  mobileColumnDirection,
  description,
  compact,
  onClick,
}) => {
  const compactClass = classNames('', {
    'card-call-to-action-compact': compact,
  });

  const teste = classNames('card-call-to-action', {
    'card-call-to-action-compact': compact,
    'card-call-to-action--column-direction': mobileColumnDirection,
  });

  const handleIconSize = iconSize
    ? { height: `${iconSize}px`, width: `${iconSize}px` }
    : {};

  function handleClick() {
    if (!onClick) return;
    onClick(title, description);
  }

  return (
    <Conditional
      condition={!!horizontalCard}
      renderIf={
        <div className="horizontal-card" onClick={handleClick}>
          <div className="horizontal-card__header">
            <img src={icon} alt={iconAlt} style={handleIconSize} />

            <Text as="p" variant={'body-small'} variantDesk={'heading-five'}>
              {title}
            </Text>

            <Conditional
              condition={!!tag}
              renderIf={
                <div className="horizontal-card__tag">
                  <Text
                    as="p"
                    variant={'body-small'}
                    variantDesk={'body-small'}
                  >
                    {tagText}
                  </Text>
                  <img src={giftBox} alt="Caixa de presente" />
                </div>
              }
            />
          </div>
          <div className="horizontal-card__chevron">
            <img src={chevronBlue} alt="Seta para direita" />
          </div>
        </div>
      }
      renderElse={
        <div className={teste} onClick={handleClick}>
          <div className={`card-call-to-action__texts ${compactClass}`}>
            <Text
              as="h3"
              variant={compact ? 'body-small' : 'heading-four'}
              variantDesk={compact ? 'heading-five' : 'heading-three'}
            >
              <strong dangerouslySetInnerHTML={{ __html: title }} />
            </Text>

            <Conditional
              condition={!!description && !compact}
              renderIf={
                <Text as="p" variant="body-small" variantDesk="body-medium">
                  <span dangerouslySetInnerHTML={{ __html: description! }} />
                </Text>
              }
            />
          </div>
          <div className={`card-call-to-action__icon ${compactClass}`}>
            <img src={icon} alt={iconAlt} style={handleIconSize} />
          </div>
        </div>
      }
    />
  );
};
