import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { delay } from '@libs/utils/helpers/delay';

import { RouteMap } from '@common/constants/RouteMap';
import { useTracking } from '@common/hooks/useTracking';

export const useCreditProfileOverview = () => {
  const { elementClickedEvent } = useTracking();
  const navigate = useNavigate();

  function handleNavigate(goTo: RouteMap) {
    elementClickedEvent({
      elementType: 'button',
      location: 'dashboard - meu cpf',
      name: goTo?.toString() || 'legacy-debts',
    });

    navigate(goTo);
  }

  async function handleRedirect() {
    elementClickedEvent({
      elementType: 'button',
      location: 'dashboard - meu cpf',
      name: `redirect CP contasepagamentos`,
    });

    await delay(150);
    window.open('https://painel-consumidor.boavistaservicos.com.br/', '_blank');
  }

  return {
    handleNavigate,
    handleRedirect,
  };
};
