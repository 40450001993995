import './styles.scss';

export interface SkeletonProps {
  width: string;
  height: string;
  borderRadius: string;
}
export const Skeleton: React.FC<SkeletonProps> = ({
  width,
  height,
  borderRadius,
}) => {
  const skeletonStyle: React.CSSProperties = {
    width,
    height,
    borderRadius,
  };
  return <div className="skeleton" style={skeletonStyle} />;
};
