import errorGauge from '@common/assets/illustration/error-gauge.svg';
import errorMan from '@common/assets/illustration/error-man-gauge.svg';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTracking } from '@common/hooks/useTracking';
import { Text } from 'iq-blueberry';
import { Container } from '@common/components/Container';
import { CardUpdateApp } from '@common/components/CardUpdateApp';
import './styles.scss';

export const ScoreError: React.FC = () => {
  const { pathname } = useLocation();
  const { elementViewedEvent } = useTracking();

  useEffect(() => {
    elementViewedEvent({
      elementType: 'content',
      location: pathname,
      name: 'score error',
    });
  }, []);

  return (
    <Container>
      <div className="score-gauge-error">
        <CardUpdateApp />
        <div className="score-gauge-error__img">
          <img
            className="img-error-gauge"
            src={errorGauge}
            alt={'Medidor de score com um ponto de interrogação no centro'}
          />
          <img
            className="img-error-man"
            src={errorMan}
            alt={
              'Homem segurando um medidor de score com um ponto de interrogação no centro'
            }
          />
        </div>

        <div className="score-gauge-error__text">
          <Text
            as="h2"
            variant="heading-five"
            variantDesk="heading-four"
            defaultColor="title"
          >
            Ops! Ocorreu um erro e não conseguimos acessar seu Score
          </Text>
          <Text as="p" variant="body-medium" variantDesk="body-large">
            No momento estamos tendo problemas para acessar suas informações de
            Score, mas fique tranquilo, já estamos trabalhando para resolver
            isso.
          </Text>
        </div>
      </div>
    </Container>
  );
};
