import { Text } from 'iq-blueberry';

import { Modal } from '@common/components/Modal';
import { HubCard } from '../HubCard';

import { useHubModal } from './hook';
import './styles.scss';
export const HubModal: React.FC = () => {
  const { handleClose, showModal, offers, handleClick, title } = useHubModal();

  return (
    <Modal
      desktop="center"
      mobile="bottom"
      showModal={showModal}
      hasCloseButton
      onClose={handleClose}
      className="hub-modal"
    >
      <div className="hub-modal__content">
        <div className="hub-modal__title">
          <Text
            as="h2"
            variant="heading-five"
            variantDesk="heading-three"
            defaultColor="title"
          >
            {title}
          </Text>
        </div>
        <div className="hub-modal__cards">
          {offers.map((offer, index) => (
            <HubCard
              key={`hub-modal-${index}`}
              offer={offer}
              position={index}
              variant="compact"
              handleClick={handleClick}
              cardLocation="popup-ofertas"
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};
