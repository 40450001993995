import { useState, useEffect, useRef } from 'react';
import { useTracking } from '@common/hooks/useTracking';
import { useApiScore } from '@tally/apis/score/useApiScore';
import { handleErrorLog } from '@common/utils/handleErrorLogs';
import { ReportConfirmDialog } from './data';
import useConfirmDialog from '../ConfirmDialog/hook';

type ToastType = 'success' | 'info' | 'loading';

interface HandleShowToastParams {
  type: ToastType;
  message: string;
}

export const useReportCpfBanner = () => {
  const { CpfReport } = useApiScore();
  const { confirm, ConfirmDialog } = useConfirmDialog();
  const { elementClickedEvent, elementViewedEvent } = useTracking();

  const [toastType, setToastType] = useState<ToastType>('loading');
  const [toastMessage, setToastMessage] = useState('');
  const [toastShow, setToastShow] = useState(false);
  const [toastKey, setToastKey] = useState(0);
  const reportRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!reportRef.current) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;

        elementViewedEvent({
          elementType: 'card/button',
          name: 'report-cpf-banner',
          location: window.location.href,
        });
        observer.disconnect();
      });
    });
    observer.observe(reportRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const incrementToastKey = () => setToastKey((prev) => prev + 1);

  const handleShowToast = ({ type, message }: HandleShowToastParams) => {
    setToastType(type);
    setToastMessage(message);
    setToastShow(true);
    incrementToastKey();

    elementClickedEvent({
      elementType: 'button',
      name: 'report-cpf-banner',
      location: window.location.pathname,
      data: {
        type: type,
        message: message,
      },
    });
  };

  const hideToast = () => {
    setToastShow(false);
  };

  const sendReport = async () => {
    try {
      handleShowToast({
        type: 'loading',
        message: 'Estamos processando seu pedido!',
      });
      const { code } = await CpfReport.send();

      if (code === 200) {
        hideToast();
        handleShowToast({
          type: 'success',
          message: 'Relatório enviado.',
        });
      } else if (code === 208) {
        hideToast();
        handleShowToast({
          type: 'info',
          message: 'Por favor, aguarde 24h para solicitar um novo relatório.',
        });
      }
    } catch (error) {
      handleErrorLog(error);
      handleShowToast({
        type: 'info',
        message: 'Erro ao solicitar relatório.',
      });
    }
  };

  const handleAction = async () => {
    const userConfirmed = await confirm({
      title: ReportConfirmDialog.title,
      message: ReportConfirmDialog.message,
      buttonFalse: ReportConfirmDialog.buttonFalse,
      buttonTrue: ReportConfirmDialog.buttonTrue,
      iconButtonTrue: ReportConfirmDialog.iconButtonTrue,
    });

    if (userConfirmed) {
      sendReport();
      elementClickedEvent({
        elementType: 'button',
        name: 'report-cpf-banner',
        location: window.location.pathname,
        data: {
          type: 'Request',
          message: 'Relatório solicitado.',
        },
      });
    } else {
      elementClickedEvent({
        elementType: 'button',
        name: 'report-cpf-banner',
        location: window.location.pathname,
        data: {
          type: 'Cancel',
          message: 'Solicitação cancelada',
        },
      });
    }
  };

  return {
    toastType,
    toastMessage,
    toastShow,
    toastKey,
    reportRef,
    handleAction,
    ConfirmDialog,
  };
};
