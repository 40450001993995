import { CardCallToAction } from '@common/components/CardCallToAction';
import { RouteMap } from '@common/constants/RouteMap';
import { useRaiseScore } from './hooks';
import iconRaiseScoreCP from '@tally/assets/badges/score-section-cp.svg';
import iconHistoricCP from '@tally/assets/icons/purple-chart-cp.svg';
import iconProgressCP from '@tally/assets/icons/purple-chart-bars-cp.svg';

import './styles.scss';
export const RaiseScore: React.FC = () => {
  const { handleRedirect } = useRaiseScore();

  return (
    <div className="raise-score">
      <div className="raise-score__content">
        <div className="raise-score__content__cards">
          <CardCallToAction
            title={'Aumentar meu Score'}
            icon={iconRaiseScoreCP}
            iconAlt="Hexágono verde claro com uma estrela preta dentro"
            description="Ver meus objetivos"
            onClick={(title) => handleRedirect(RouteMap.Objectives, title)}
            horizontalCard
            tagText="Grátis!"
            tag
          />

          <CardCallToAction
            title="Histórico de Score"
            icon={iconHistoricCP}
            iconAlt="Quadrado verde com barras representando um gráfico com altos e baixos"
            onClick={(title) =>
              handleRedirect(RouteMap.ObjectivesHistoric, title)
            }
            horizontalCard
          />
          <CardCallToAction
            title="Ver meu progresso"
            icon={iconProgressCP}
            iconAlt="Três barras verdes lado a lado aumentando progressivamente com uma linha paralela à elas indicando a progressão"
            onClick={(title) =>
              handleRedirect(RouteMap.ObjectivesProgress, title)
            }
            horizontalCard
          />
        </div>
      </div>
    </div>
  );
};
