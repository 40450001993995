import { HubModal } from '@hub/components/HubModal';
import { CreditProfileOverview } from '@dues/components/CreditProfileOverview';
import { MyScoreOptionsOverview } from '@tally/components/MyScoreOptionsOverview';
import { OffersOverview } from '@hub/components/OffersOverview';

import { Container } from '@common/components/Container';
import { LoaderCircular } from '@common/components/LoaderCircular';
import { PrivateDefaultTemplate } from '@common/Templates/PrivateDefault';

import { useDashboard } from './hook';
import './styles.scss';
import { Tabs } from '@common/components/Tabs';
import Denakop from '@common/components/Denakop';

const Dashboard: React.FC = () => {
  const {
    score,
    hasError,
    loadingDashboard,
    isNegativeConsumer,
    customIndex,
    handleTabChange,
  } = useDashboard();

  const tabsContent = [
    {
      title: 'Meu Score',
      content: (
        <MyScoreOptionsOverview
          score={score}
          hasError={hasError}
          isNegativeConsumer={isNegativeConsumer}
        />
      ),
    },
    {
      title: 'Crédito',
      content: <OffersOverview key="tab-dash-offers" />,
    },
    {
      title: 'Meu CPF',
      content: <CreditProfileOverview key="tab-dash-overview" />,
    },
  ];
  if (loadingDashboard) {
    return (
      <PrivateDefaultTemplate>
        <Container expandY centralize>
          <LoaderCircular />
        </Container>
      </PrivateDefaultTemplate>
    );
  }
  return (
    <PrivateDefaultTemplate>
      <div className="dashboard">
        <Container customClass="dashboard__tabs">
          <Tabs
            tabsContent={tabsContent}
            customIndex={customIndex}
            handleClick={handleTabChange}
          ></Tabs>
        </Container>
        <Denakop page="home" id="1" />
      </div>
      <HubModal />
    </PrivateDefaultTemplate>
  );
};

export default Dashboard;
