import { useEffect, useState } from 'react';

interface UseTabsProps {
  customIndex: number;
  handleChange?: (tabIndex: number) => void;
  handleClick?: (tabIndex: number) => void;
}

export const useTabs = ({
  customIndex,
  handleChange,
  handleClick,
}: UseTabsProps) => {
  const [getIndex, setIndex] = useState(customIndex);

  useEffect(() => {
    changeTab(customIndex);
  }, [customIndex]);

  function changeTabClick(index: number) {
    changeTab(index);
    if (handleClick) {
      handleClick(index);
    }
  }

  function changeTab(index: number) {
    if (index !== getIndex) {
      setIndex(index);
      if (handleChange) {
        handleChange(index);
      }
    }
  }

  return { getIndex, changeTabClick };
};
