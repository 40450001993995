export const getMonth = (date: string): IMonth => {
  const normalizeSeparator = date.replace(/[^\d]/gm, '/');
  const [, month] = normalizeSeparator.split('/');

  return monthMap[Number(month)] || ' - ';
};

interface IMonth {
  short: string;
  full: string;
  number: string;
}

interface IMonthMap {
  [key: string]: IMonth;
}
const monthMap: IMonthMap = {
  '1': { short: 'jan', full: 'janeiro', number: '01' },
  '2': { short: 'fev', full: 'fevereiro', number: '02' },
  '3': { short: 'mar', full: 'março', number: '03' },
  '4': { short: 'abr', full: 'abril', number: '04' },
  '5': { short: 'mai', full: 'maio', number: '05' },
  '6': { short: 'jun', full: 'junho', number: '06' },
  '7': { short: 'jul', full: 'julho', number: '07' },
  '8': { short: 'ago', full: 'agosto', number: '08' },
  '9': { short: 'set', full: 'setembro', number: '09' },
  '10': { short: 'out', full: 'outubro', number: '10' },
  '11': { short: 'nov', full: 'novembro', number: '11' },
  '12': { short: 'dez', full: 'dezembro', number: '12' },
};
