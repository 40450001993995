import { Text, Conditional } from 'iq-blueberry';
import GaugeComponent from 'react-gauge-component';

import { UserScore } from '@tally/types';

import './styles.scss';
import { useScoreGauge } from './hook';
import { CardUpdateApp } from '@common/components/CardUpdateApp';
export interface IScoreGauge {
  score: UserScore;
  showChances?: boolean;
}
export const ScoreGauge: React.FC<IScoreGauge> = ({
  score,
  showChances = false,
}) => {
  const {
    lastUpdateMessage,
    chances,
    scoreAnimated,
    animationFinished,
    gaugeArcOptions,
    gaugeValue,
    isModalOpen,
    setIsModalOpen,
  } = useScoreGauge({
    score,
  });

  return (
    <>
      <div className="container score-gauge">
        <CardUpdateApp />
        <GaugeComponent
          id="score"
          type="semicircle"
          arc={{
            padding: 0.03,
            cornerRadius: 0,
            subArcs: gaugeArcOptions,
            width: 0.1,
          }}
          pointer={{
            type: 'blob',
            animationDelay: 0,
            elastic: false,
            width: 17,
          }}
          labels={{
            valueLabel: { hide: true },
            tickLabels: { hideMinMax: true },
          }}
          value={gaugeValue}
          minValue={1}
          maxValue={1000}
          className="score-gauge__score"
        />

        <div className="score-gauge__label">
          <Text
            as="span"
            variant="heading-one"
            className="score-gauge__label__value"
          >
            {scoreAnimated}
          </Text>
          <div className="score-gauge__label__wrapper">
            <Text
              as="span"
              variant="body-micro"
              className="score-gauge__label__last-update"
            >
              {lastUpdateMessage}
            </Text>
          </div>
        </div>

        <Conditional
          condition={showChances}
          renderIf={
            <div className="score-gauge__chances">
              <Text as="p" variant="heading-four">
                Sua chance de aprovação de crédito{' '}
                <Conditional
                  condition={animationFinished}
                  renderIf={
                    <strong dangerouslySetInnerHTML={{ __html: chances }} />
                  }
                  renderElse={<strong>está ...</strong>}
                />
              </Text>
            </div>
          }
        />
      </div>
    </>
  );
};
