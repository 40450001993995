import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { RouteMap } from '@common/constants/RouteMap';
import { getFromStorage, setOnStorage } from '@common/utils/handleStorage';

export const useMyScoreOptionsOverview = () => {
  const [nativeApp, setNativeApp] = useState(false);
  const [hasViewedMonitoring, setHasViewedMonitoring] = useState(false);

  // const navigate = useNavigate();

  useEffect(() => {
    if (window.isNativeApp) {
      setNativeApp(true);
      const viewed = getFromStorage<string>('hasViewedMonitoring');
      setHasViewedMonitoring(viewed === 'true');
    }
  }, []);

  const handleViewMonitoring = () => {
    if (nativeApp) {
      setOnStorage('local', 'hasViewedMonitoring', 'true');
      setHasViewedMonitoring(true);
    }

    // navigate(RouteMap.MonitoringCenter);
  };

  return {
    nativeApp,
    hasViewedMonitoring,
    handleViewMonitoring,
  };
};
