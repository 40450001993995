import { useEffect, useRef, useState } from 'react';
import { Text } from '@consumidor-positivo/aurora';

// Hooks
import { useTracking } from '@common/hooks/useTracking';
import { useLocation, useNavigate } from 'react-router-dom';

// Assets
import arrowRightIcon from '@common/assets/icon/chevron-right-cp.svg';
import matchMakerIllustration from '../../assets/match-maker-illustration.svg';

import './styles.scss';
import { RouteMap } from '@common/constants/RouteMap';
import useConfirmDialog from '@common/components/ConfirmDialog/hook';
import { getFromStorage, removeFromStorage } from '@common/utils/handleStorage';
import { StorageMap } from '@common/constants/StorageMap';
import { createPortal } from 'react-dom';
import { MatchMakerCache } from '@hub/types/matchmaker';

export const MatchMakerCard = () => {
  const matchMarkerRef = useRef<HTMLDivElement>(null);

  const { elementClickedEvent, elementViewedEvent } = useTracking();
  const navigate = useNavigate();
  const { ConfirmDialog, confirm } = useConfirmDialog();

  useEffect(() => {
    if (!matchMarkerRef.current) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;

        elementViewedEvent({
          elementType: 'card/button',
          name: 'match-maker-card',
          location: window.location.href,
        });
        observer.disconnect();
      });
    });
    observer.observe(matchMarkerRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleCardClick = async () => {

    const wasMatchMakerData = await getFromStorage<MatchMakerCache>(StorageMap.MatchMaker);
    if (wasMatchMakerData?.step_name === 'annuity') {
      const result = await confirm({
        title: 'Match Positivo',
        message: 'Você gostaria de refazer o quiz para ver novos resultados de cartões de crédito ou ver seu último resultado?',
        buttonTrue: 'Refazer quiz',
        buttonFalse: 'Ver resultado anterior',
      });

      if (result) {
        removeFromStorage(StorageMap.MatchMaker);
      }

      elementClickedEvent({
        elementType: 'button',
        name: 'match-maker-clean',
        location: window.location.pathname,
        text: result ? 'Refazer quiz' : 'Ver resultado anterior',
      });
    }

    elementClickedEvent({
      elementType: 'card/button',
      name: 'match-maker-card',
      location: window.location.href,
    });

    navigate(RouteMap.MatchMaker);
  };

  return (
    <>
      <div
        className="match-maker__card"
        ref={matchMarkerRef}
        onClick={handleCardClick}
      >
        <div className="match-maker__card__content">
          <Text
            as="h2"
            variant="heading-micro"
            variantDesk="heading-medium"
            weight="light"
          >
            Encontre o seu
            <strong> cartão de crédito ideal!</strong>
          </Text>

          <div className="match-maker__card__content__link">
            <Text as="p" variant="body-medium" variantDesk="body-large">
              Conhecer cartões
            </Text>
            <img
              src={arrowRightIcon}
              alt="Seta apontando para o lado direito"
            />
          </div>
        </div>
        <div className="match-maker__card__image">
          <img
            src={matchMakerIllustration}
            alt="Ilustração de uma mulher com os braços crusados e apontando para 3 cartões de crédito."
          />
        </div>
      </div>
      {createPortal(ConfirmDialog, document.body)}
    </>
  );
};
