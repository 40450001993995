import { createPortal } from 'react-dom';
import { Button, Conditional, Text } from 'iq-blueberry';
import { SHDecisionItem } from '@common/hooks/useSortingHat';
import IconChevron from '@common/assets/icon/chevron-right-cp.svg';

import { HubTag } from './components/HubTag';
import { HubHighlights } from './components/HubHighlights';

import { useHubCard } from './hook';
import './styles.scss';
import { RedirectModal } from '../RedirectModal';
import classNames from 'classnames';

export type HubCardProps = {
  offer: SHDecisionItem;
  position: number;
  handleClick?: (offer?: SHDecisionItem) => Promise<void>;
  variant?: 'compact' | 'default';
  cardLocation?: string;
};
export const HubCard: React.FC<HubCardProps> = ({
  offer,
  position,
  handleClick,
  cardLocation,
  variant = 'default',
}) => {
  const {
    cardRef,
    onClick,
    redirectModal,
    loadingRedirect,
    withDetailButton,
    handleDetailRedirect
  } = useHubCard({
    offer,
    position,
    cardLocation,
    handleClick,
  });

  const hasLimit =  Boolean(offer?.generalInfo?.limit);
  const edditedLimit = new Intl.NumberFormat('pt-BR', {
    maximumFractionDigits: 0,
  }).format(offer.generalInfo.limit || 0);
  const headerDescription = hasLimit
    ? `Limite de R$${edditedLimit}`
    : offer?.content?.description;
  //#endregion

  const variantClass = classNames('hub-card', {
    'hub-card--compact': variant === 'compact',
  });

  const actionClass = classNames('hub-card__action', {
    'hub-card__action--details': withDetailButton,
  });

  const descriptionClass = classNames('hub-card__header__description', {
    'hub-card__header__description--limit-test': hasLimit,
  });

  return (
    <>
      <div className={variantClass} ref={cardRef}>
        <div className="hub-card__content">
          <div className="hub-card__header">
            <div className="hub-card__image">
              <img
                src={offer.content?.image_logo?.filename}
                alt={offer?.content.image_logo?.alt || offer.content.title}
              />
            </div>
            <div className="hub-card__header__text">
              <Conditional
                condition={hasLimit}
                renderIf={
                  <Text
                    as="p"
                    variant="body-medium"
                    defaultColor="title"
                    className="hub-card__header__text-limit"
                  >
                    {offer?.content?.title || ''}
                  </Text>
                }
                renderElse={
                  <Text as="h4" variant="heading-five" defaultColor="title">
                    {offer?.content?.title || ''}
                  </Text>
                }
              />
              <div className={descriptionClass}>
                <Conditional
                  condition={hasLimit}
                  renderIf={
                    <Text
                      as="h4"
                      variant="heading-five"
                      variantDesk="heading-four"
                      defaultColor="title"
                    >
                      {headerDescription || ''}
                    </Text>
                  }
                  renderElse={
                    <Text as="p" variant="body-micro" defaultColor="title">
                      {headerDescription || ''}
                    </Text>
                  }
                />
              </div>
            </div>
            <div className="hub-card__header__icon">
              <img src={IconChevron} alt="Seta azul para direita" />
            </div>
          </div>

          <div className="hub-card__body">
            <HubTag
              isPreApproved={offer?.generalInfo?.preApproved}
              limit={offer?.generalInfo?.limit}
              featureInformation={offer?.content?.feature_information}
            />
            <div className="hub-card__body__highlights">
              <HubHighlights
                className="hub-card__body__topics"
                topics={offer?.content?.highlights_card_iq}
              />

              <Conditional
                condition={offer?.content?.subject_to_credit_approval}
                renderIf={
                  <Text
                    as="p"
                    variant="body-micro"
                    defaultColor="title"
                    className="hub-card__body__disclaimer"
                  >
                    *Sujeito à análise de crédito
                  </Text>
                }
              />
            </div>
          </div>
        </div>
        <div className={actionClass}>
          <Button
            color="default"
            disabled={false}
            expand="x"
            justify="center"
            loading={loadingRedirect}
            onClick={onClick}
            size="medium"
            type="primary"
          >
            {offer?.content?.button_text || 'Pedir agora'}
          </Button>
          <Conditional
            condition={offer?.content?.detail_page || false}
            renderIf={
              <Button
                color="default"
                disabled={false}
                expand="x"
                justify="center"
                loading={false}
                size="medium"
                type="secondary"
                onClick={handleDetailRedirect}
              >
                <Conditional
                  condition={variant === 'compact'}
                  renderIf="Ver detalhes"
                  renderElse="Ver mais detalhes"
                />
              </Button>
            }
          />
        </div>
        <div className="hub-card__action-full" onClick={onClick}></div>
      </div>
      {createPortal(
        <RedirectModal
          isOpen={redirectModal.isOpen}
          partnerName={redirectModal.name}
          offerImage={redirectModal.image}
        />,
        document.body
      )}
    </>
  );
};
