import { useState, useEffect } from 'react';
import {
  Conditional,
  IconCheck,
  IconInfo,
  IconLoader,
  IconX,
  Text,
} from '@consumidor-positivo/aurora';

import './styles.scss';
import classNames from 'classnames';

type ToastType = 'success' | 'info' | 'loading';

interface Toast {
  id: number;
  message: string;
  type: ToastType;
}

interface ToastManagerProps {
  toastType: ToastType;
  toastMessage: string;
  toastShow: boolean;
}

const ToastManager: React.FC<ToastManagerProps> = ({
  toastType,
  toastMessage,
  toastShow,
}) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  useEffect(() => {
    if (toastShow && toastMessage) {
      addToast(toastMessage, toastType);
    }
  }, [toastShow, toastMessage, toastType]);

  const addToast = (message: string, type: ToastType) => {
    const id = Date.now();
    setToasts((prev) => [...prev, { id, message, type }]);

    if (type !== 'loading') {
      setTimeout(() => removeToast(id), 3000);
    }
  };

  const removeToast = (id: number) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };

  const renderIcon = (type: ToastType) => {
    switch (type) {
      case 'success':
        return <IconCheck />;
      case 'info':
        return <IconInfo />;
      case 'loading':
        return <IconLoader className="toast__loading" />;
      default:
        return null;
    }
  };

  const classToastType = classNames('toast__dialog', {
    'toast__dialog--success': toastType === 'success',
    'toast__dialog--info': toastType === 'info',
    'toast__dialog--loading': toastType === 'loading',
  });

  return (
    <div className="toast">
      {toasts.map((toast) => (
        <div key={toast.id} className={classToastType}>
          <div className="toast__content" onClick={() => removeToast(toast.id)}>
            {renderIcon(toast.type)}

            <Text as="p" variant="body-medium">
              {toast.message}
            </Text>
          </div>

          <button className="toast-close">
            <IconX />
          </button>
        </div>
      ))}
    </div>
  );
};

export default ToastManager;
