import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDogxContext } from '@libs/dogx';
import { delay } from '@libs/utils/helpers/delay';

import { RouteMap } from '@common/constants/RouteMap';
import { useTracking } from '@common/hooks/useTracking';
import { SHDecisionItem } from '@common/hooks/useSortingHat';
import { ProductViewedEventPayload } from '@common/hooks/useTracking/types';

import { useHubRedirect } from '@hub/hooks/useHubRedirect';
import { HubCardProps } from '.';

export const useHubCard = ({
  offer,
  position,
  handleClick,
  cardLocation,
}: HubCardProps) => {
  const navigate = useNavigate();
  const { productViewedEvent, elementClickedEvent } = useTracking();
  const { getExperimentVariant } = useDogxContext();

  const { handleOfferRedirect, loadingRedirect, redirectModal } =
    useHubRedirect();
  const cardRef = useRef<HTMLDivElement>(null);

  const withDetailButton =
    offer?.content?.detail_page;

  useEffect(() => {
    const observer = new IntersectionObserver(async (entries, observer) => {
      if (entries[0].isIntersecting) {
        await delay(150);
        cardViewedEvent(offer, position);
      }
    });

    if (cardRef.current) observer.observe(cardRef.current);
    return () => {
      if (cardRef.current) observer.unobserve(cardRef.current);
    };
  }, [cardRef]);

  function cardViewedEvent(offer: SHDecisionItem, position: number) {
    const productViewedEventPayload: ProductViewedEventPayload = {
      brand: offer?.generalInfo.partner,
      category: offer?.generalInfo.category,
      location: cardLocation || location.pathname,
      name: offer?.metadata.name,
      position,
      productId: offer?.generalInfo.slug,
      text: offer?.content?.title,
      variant: offer?.content?.variant,
      partner: offer?.generalInfo.partner,
    };

    productViewedEvent(productViewedEventPayload);
  }

  async function onClick() {
    if (!!handleClick) {
      await handleClick(offer);
    }

    await handleOfferRedirect({
      offer,
      position,
      location: cardLocation,
    });
  }

  async function handleDetailRedirect() {
    elementClickedEvent({
      elementType: 'card/button',
      name: offer.content.slug,
      location: cardLocation,
      text: 'Ver mais detalhes',
      position: `${position}`,
    });

    const detailUrl = RouteMap.ProductDetail.replace(
      ':slug',
      offer.content.slug
    );
    await delay(150);
    return navigate(detailUrl);
  }

  return {
    cardRef,
    onClick,
    loadingRedirect,
    redirectModal,
    handleDetailRedirect,
    withDetailButton
  };
};
