import classNames from 'classnames';
import './styles.scss';
import { Text } from 'iq-blueberry';

export interface ICardIconProps {
  icon: string;
  iconAlt: string;
  text: string;
  maxWidth?: string;
  reverse?: boolean;
}
export const CardIcon: React.FC<ICardIconProps> = ({
  icon,
  iconAlt,
  text,
  maxWidth = '100%',
  reverse,
}) => {
  const contentClass = classNames('common-card-icon__content', {
    'common-card-icon__content--reverse': reverse,
  });
  return (
    <div className="common-card-icon" style={{ maxWidth }}>
      <div className={contentClass}>
        <img src={icon} alt={iconAlt} />
        <Text as="p" variant="body-small">
          <strong dangerouslySetInnerHTML={{ __html: text }} />
        </Text>
      </div>
    </div>
  );
};
