import { useNavigate } from 'react-router-dom';

import { RouteMap } from '@common/constants/RouteMap';
import { useTracking } from '@common/hooks/useTracking';

export const useRaiseScore = () => {
  const navigate = useNavigate();
  const { elementClickedEvent } = useTracking();

  function handleRedirect(route: RouteMap, title: string) {
    clickedEvent(route, title);
    navigate(route);
  }

  function clickedEvent(route: RouteMap, title: string) {
    elementClickedEvent({
      elementType: 'card',
      name: route,
      text: title,
    });
  }

  return {
    handleRedirect,
    clickedEvent,
  };
};
