import { Conditional } from 'iq-blueberry';
import { RaiseScore } from '@tally/components/RaiseScore';
import { ScoreGauge } from '@tally/components/ScoreGauge';
import { ScoreError } from '@tally/components/ScoreError';
import { NegativeConsumer } from '@tally/components/NegativeConsumer';
import { MonitoringStatus } from '@common/components/MonitoringStatus';
// import { MonitoringCenterBanner } from '@common/components/MonitoringCenterBanner';

import { UserScore } from '@tally/types';
import { useMyScoreOptionsOverview } from './hook';

import './styles.scss';
import { ScoreTipsBanner } from '@common/components/ScoreTipsBanner';
import { AppStoresBanner } from '@common/components/AppStoresBanner';

type MyScoreOptionsOverviewProps = {
  score: UserScore | null;
  hasError: boolean;
  isNegativeConsumer: boolean;
};

export const MyScoreOptionsOverview: React.FC<MyScoreOptionsOverviewProps> = ({
  score,
  hasError,
  isNegativeConsumer,
}) => {
  const { nativeApp, hasViewedMonitoring } = useMyScoreOptionsOverview();

  return (
    <div>
      <MonitoringStatus />

      <div className="my-score__score">
        <Conditional
          condition={hasError || !score}
          renderIf={<ScoreError />}
          renderElse={<ScoreGauge score={score!} showChances />}
        />

        <Conditional
          condition={isNegativeConsumer}
          renderIf={<NegativeConsumer key="tab-dash-negative" />}
          renderElse={
            <Conditional
              condition={hasViewedMonitoring && nativeApp}
              renderIf={<RaiseScore key="tab-dash-raise" />}
              renderElse={
                <div className="my-score__score__options">
                  {/* <MonitoringCenterBanner
                    onViewMonitoring={handleViewMonitoring}
                  /> */}
                  <RaiseScore key="tab-dash-raise" />
                  <Conditional
                    condition={!window.isNativeApp}
                    renderIf={<AppStoresBanner />}
                  />
                  <div className="my-score__score__options__divider" />
                  <ScoreTipsBanner />
                </div>
              }
            />
          }
        />
      </div>
    </div>
  );
};
