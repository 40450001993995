import { useNavigate } from 'react-router-dom';

import { RouteMap } from '@common/constants/RouteMap';
import { SHDecisionItem, useSortingHat } from '@common/hooks/useSortingHat';
import { useEffect } from 'react';
import { useTracking } from '@common/hooks/useTracking';
import { ModalDataEventProp } from '@common/hooks/useTracking/types';

export const useOffersOverview = () => {
  const { shDecisionList } = useSortingHat();
  const { elementClickedEvent, modalViewedEvent } = useTracking();
  const navigate = useNavigate();

  useEffect(() => {
    if (shDecisionList.length > 0) {
      handleModalViewedEvent(shDecisionList);
    }
  }, [shDecisionList]);

  // FIXME: The component isn't a modal, we trigger the wrong event
  // In this case can we use element viewed event?
  function handleModalViewedEvent(data: SHDecisionItem[]) {
    const cardsDisplayed: ModalDataEventProp[] = data.map((item) => {
      return {
        cardSlug: item?.content?.slug,
        partner: item?.content?.partner,
      };
    });

    modalViewedEvent({ modalData: cardsDisplayed, location: 'modal-ofertas' });
  }

  function goToOffers() {
    elementClickedEvent({
      elementType: 'button',
      location: 'dash/tab-ofertas',
      text: 'Conhecer mais ofertas',
    });
    navigate(RouteMap.Offer);
  }
  return { shDecisionList, goToOffers };
};
