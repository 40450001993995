import { LegacyRef, useCallback, useEffect, useState } from 'react';
import { useTracking } from '@common/hooks/useTracking';

import { InfoCard } from '@common/components/InfoCard';

import './styles.scss';
type DeniedPushNotificationBannerProps = {
  text: string;
};

function getPushNotificationStatus() {
  return new Promise<boolean>((resolve) => {
    const hasPushNotificationStatus =
      'isPushNotificationEnabled' in window.NativeSettings!;
    if (hasPushNotificationStatus)
      return resolve(!!window.NativeSettings?.isPushNotificationEnabled);

    const interval = setInterval(() => {
      if (!hasPushNotificationStatus) return;

      clearInterval(interval);
      resolve(!!window.NativeSettings?.isPushNotificationEnabled);
    }, 1000);
  });
}

export const DeniedPushNotificationBanner = ({
  text,
}: DeniedPushNotificationBannerProps) => {
  const [showBanner, setShowBanner] = useState(false);
  const { elementClickedEvent, elementViewedEvent } = useTracking();

  useEffect(() => {
    // Check if the user has already closed the banner
    const status = sessionStorage.getItem('pnb_status');
    if (status === 'false') return;

    if (!window.NativeFeatures?.PushNotificationStatus) return;

    (async () => {
      const pushNotificationStatus = await getPushNotificationStatus();
      setShowBanner(!pushNotificationStatus);
    })();
  }, []);

  const onComponentViewed = useCallback((element: HTMLDivElement) => {
    if (!element) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;

        elementViewedEvent({
          name: 'denied-push-notification-banner',
          elementType: 'card',
        });
        observer.disconnect();
      });
    });

    observer.observe(element);
  }, []);

  const handleCloseBanner = () => {
    setShowBanner(false);

    // Save status on session storage
    sessionStorage.setItem('pnb_status', 'false');

    elementClickedEvent({
      elementType: 'button',
      name: 'denied-push-notification-banner',
      text: 'close-banner',
    });
  };

  const handleEnablePushNotifications = () => {
    elementClickedEvent({
      elementType: 'button',
      name: 'denied-push-notification-banner',
      text: 'enable-push-notifications',
    });

    const message = { action: 'openNotificationSettings' };
    window.ReactNativeWebView.postMessage(JSON.stringify(message));

    setShowBanner(false);
  };

  // Don't show the banner if it's not a native app
  if (!window.isNativeApp) return null;

  // Don't show the banner if the feature is not available
  if (!window.NativeFeatures?.PushNotificationStatus) return null;

  // Don't show the banner if the user has already enabled push notifications
  if (!showBanner) return null;

  return (
    <div
      ref={onComponentViewed}
      className="push-notification-banner__container"
    >
      <InfoCard
        infoText={text}
        buttonText="Ativar minhas notificações"
        handleClose={handleCloseBanner}
        handleAction={handleEnablePushNotifications}
      />
    </div>
  );
};
