import semver from 'semver';

import IconWarning from '@common/assets/icon/warning.svg';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';

import './styles.scss';

type CardUpdateAppProps = {
  style?: React.CSSProperties;
};

export const CardUpdateApp = ({ style }: CardUpdateAppProps) => {
  const handleOpenStore = () => {
    let redirectURL =
      'https://play.google.com/store/apps/details?id=br.com.foursys.boavistaconsumidor&hl=pt_BR';
    const isIOS = window.navigator.userAgent.match(/iPhone|iPad|iPod/i);

    if (isIOS)
      redirectURL =
        'https://apps.apple.com/br/app/consumidor-positivo/id1482315306';

    window.open(redirectURL, '_top');
  };

  if (!window.isNativeApp) return null;

  const appVersion = getCookie('app-version');
  if (!appVersion) return null;

  if (semver.gte(appVersion, '6.8.1')) return null;

  return (
    <div
      className="update-card update-card--warning"
      style={style}
      onClick={handleOpenStore}
    >
      <img className="update-card__icon" src={IconWarning} alt="warning" />
      <div className="update-card__content">
        <h3 className="update-card__title">Atualize seu aplicativo</h3>
        <p className="update-card__text">
          <b>Toque aqui para atualizar</b> e continuar recebendo informações do
          seu Score.
        </p>
      </div>
    </div>
  );
};
