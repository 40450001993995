import { Conditional, Text } from 'iq-blueberry';

import { ErrorBanner } from '@common/components/ErrorBanner';

import { AcModal } from '@dues/components/AcModal';
import { EmptyCard } from '@dues/components/EmptyCard';
import { DebtsCard } from '@dues/components/DebtsCard';
import SkeletonCard from '@dues/components/SkeletonCard';
import { Spinetic, SpineticItem } from 'react-spinetic';

import infoCircle from '@dues/assets/icon/InfoCircle.svg';
import logoAc from '@dues/assets/logo-acordo-certo.svg';

import './styles.scss';
import { useMyDebts } from './hook';
import { offerCarouselConfig } from './config';

export const DebtsOfferBanner: React.FC = () => {
  const {
    sortingDebtLoading,
    sortingDebtError,
    debtsList,
    sortingDebtComplete,
    isModalOpen,
    setIsModalOpen,
    handleNegotiate,
  } = useMyDebts();

  if (!sortingDebtLoading && sortingDebtError) {
    return (
      <div>
        <ErrorBanner
          variant="dividas"
          eventName="Tab CPF - Erro no retorno das dividas"
        />
      </div>
    );
  }

  return (
    <>
      <AcModal
        isModalOpen={isModalOpen}
        setIsModalOpen={() => setIsModalOpen(false)}
        handleNegotiate={handleNegotiate}
      />

      <div className="debts-offer-banner">
        <div className="debts-offer-banner__content">
          <Conditional
            condition={sortingDebtComplete}
            renderIf={
              debtsList?.length === 0 ? (
                <></>
              ) : (
                <div className="debts-offer-banner__subtitle">
                  <Text as="p" variant="body-medium">
                    Encontramos as seguintes ofertas de negociação na{' '}
                    <strong>Acordo Certo</strong>
                  </Text>
                  <img
                    className="debts-offer-banner__subtitle__logo"
                    src={logoAc}
                    alt="Logo da Acordo Certo"
                    onClick={() => setIsModalOpen(true)}
                  />
                  {/* <img
                    src={infoCircle}
                    alt="Ícone de um círculo com um 'i' ao centro representando informação."
                    onClick={() => setIsModalOpen(true)}
                  /> */}
                </div>
              )
            }
            renderElse={
              <div className="debts-offer-banner__subtitle">
                <Text as="p" variant="body-medium" variantDesk="body-large">
                  Estamos verificando se você tem alguma dívida para{' '}
                  <strong>negociar na Acordo Certo</strong>{' '}
                </Text>
                <img
                  className="debts-offer-banner__subtitle__logo"
                  src={logoAc}
                  alt="Logo da Acordo Certo"
                  onClick={() => setIsModalOpen(true)}
                />
              </div>
            }
          />

          <div className="debts-offer-banner__list">
            <Conditional
              condition={debtsList.length > 0}
              renderIf={
                <Spinetic config={offerCarouselConfig}>
                  {debtsList.map((item, index) => (
                    <SpineticItem key={index}>
                      {item.isLoading ? (
                        <SkeletonCard carouselUse fullSkeleton />
                      ) : (
                        <DebtsCard
                          debt={item.item}
                          position={index}
                          carouselUse
                        />
                      )}
                    </SpineticItem>
                  ))}
                </Spinetic>
              }
              renderElse={
                <EmptyCard setIsModalOpen={() => setIsModalOpen(true)} />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
