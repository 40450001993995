import { useRequest } from 'alova';

import { alovaInstanceAuth } from './alovaInstanceOnboarding';

import {
  transformSigninPayload,
  transformSignupPayload,
  transformCheckDocumentPayload,
  transformForgotPasswordPayload,
  transformForgotPasswordValidatePayload,
  transformForgotPasswordFindPayload,
  transformForgotPasswordResetPayload,
  transformChangePasswordPayload,
  transformForgotPasswordValidatePayloadLegacy,
  transformGenerateOTPPayload,
  transformCheckOTPPayload,
} from './transformPayload';

import {
  ApiAuthResponse,
  ApiChangePasswordResponse,
  ApiEmailChangeResponse,
  ApiForgotPasswordFindResponse,
  ApiForgotPasswordResponse,
  ApiKbaGetQuestionResponse,
  ApiKbaGetQuestionsRequest,
  ApiSendEmailResponse,
  ApiSigninResponse,
  ApiSignupEmailValidate,
  User,
  ApiFacebookSigninResponse,
	ApiCheckDocumentResponseData,
  ApiGenerateOTPResponse,
} from './types';

import { SortingDebitResponse } from '@dues/types/sorting-debt';
import { removeMask } from '@libs/utils/masks/cpf';

const useApiAuth = () => {
  const apiAuthSignin = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiSigninResponse>>(
        '/v1/public/auth/signin',
        {
          ...transformSigninPayload(payload),
        },
        {
          headers: window.isNativeApp
            ? { 'x-mobile-app': true }
            : {},
        }
      ),
    {
      immediate: false,
    }
  );

  const apiAuthFacebookSignin = useRequest(
    alovaInstanceAuth.Get<ApiAuthResponse<ApiFacebookSigninResponse>>(
      '/v1/public/auth/signin/facebook',
    ),
    { immediate: false }
  );

  const apiAuthRefreshToken = useRequest(
    () =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiSigninResponse>>(
        '/v1/public/auth/refresh-token',
      ),
    {
      immediate: false,
    }
  );

  const apiAuthSignup = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiSigninResponse>>(
        '/v1/public/auth/signup',
        { ...transformSignupPayload(payload) },
      ),
    {
      immediate: false,
    }
  );

  const apiAuthCheckDocument = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiCheckDocumentResponseData>>(
        `/v1/public/auth/track`,
        {
          ...transformCheckDocumentPayload(payload),
        },
      ),
    {
      immediate: false,
    }
  );

  const apiAuthForgotPassword = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiForgotPasswordResponse>>(
        '/v1/public/auth/forgot-password',
        {
          ...transformForgotPasswordPayload(payload),
        },
      ),
    {
      immediate: false,
    }
  );

  const apiAuthForgotPasswordLegacy = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiForgotPasswordResponse>>(
        '/v1/public/legacy/forgot-password',
        {
          ...transformForgotPasswordPayload(payload),
        },
      ),
    {
      immediate: false,
    }
  );

  const apiAuthForgotPasswordValidate = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<{ transition_token?: string }>>(
        '/v1/public/auth/forgot-password/validate',
        {
          ...transformForgotPasswordValidatePayload(payload),
        },
      ),
    {
      immediate: false,
    }
  );

  const apiAuthForgotPasswordKBAQuestion = useRequest(
    (payload: any) =>
      alovaInstanceAuth.Post<any>(
        '/v1/public/kba/forgot-password/question',
        {
					...payload,
					"g-recaptcha-response": payload["g-recaptcha-response"],
					"g-recaptcha-action": payload["g-recaptcha-action"],
				},
      ),
    {
      immediate: false,
    }
  );

  const apiAuthForgotPasswordKBAAnswer = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<any>(
        '/v1/public/kba/forgot-password/answer',
        payload,
      ),
    {
      immediate: false,
    }
  );

  const apiAuthForgotPasswordKBAReset = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<{ transition_token?: string }>>(
        '/v1/public/kba/forgot-password/reset',
        payload,
      ),
    {
      immediate: false,
    }
  );

  const apiAuthForgotPasswordLegacyValidate = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<{ transition_token?: string }>>(
        '/v1/public/legacy/forgot-password/validate',
        {
          ...transformForgotPasswordValidatePayloadLegacy(payload),
        },
      ),
    {
      immediate: false,
    }
  );

  const apiAuthForgotPasswordFind = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiForgotPasswordFindResponse>>(
        '/v1/public/auth/forgot-password/find',
        {
          ...transformForgotPasswordFindPayload(payload),
        },
      ),
    {
      immediate: false,
    }
  );

  const apiAuthForgotPasswordReset = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<void>>(
        '/v1/public/auth/forgot-password/reset',
        {
          ...transformForgotPasswordResetPayload(payload),
        },
      ),
    {
      immediate: false,
    }
  );

  const apiAuthForgotPasswordResetLegacy = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<void>>(
        '/v1/public/legacy/forgot-password/reset',
        payload,
      ),
    {
      immediate: false,
    }
  );

  const apiAuthCustomer = useRequest(
    alovaInstanceAuth.Get<ApiAuthResponse<User>>('/v1/app/customer'),
    { immediate: false }
  );

  const apiAuthChangePassword = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiChangePasswordResponse>>(
        'v1/app/password',
        {
          ...transformChangePasswordPayload(payload),
        },
      ),
    { immediate: false }
  );

  const apiAuthDeleteAccount = useRequest(
    alovaInstanceAuth.Delete('v1/app/delete'),
    { immediate: false }
  );

  const apiAuthEmailSendToken = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiEmailChangeResponse>>(
        'v1/app/email/send-token',
        payload,
      ),
    { immediate: false }
  );

  const apiAuthEmailValidateTokenAndUpdate = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiEmailChangeResponse>>(
        'v1/app/email/validate-token',
        payload,
      ),
    { immediate: false }
  );

  const apiAuthGetKbaQuestions = useRequest(
    (payload: ApiKbaGetQuestionsRequest) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiKbaGetQuestionResponse>>(
        '/v1/public/kba/question',
        {
					...payload,
					"g-recaptcha-response": payload["g-recaptcha-response"],
					"g-recaptcha-action": payload["g-recaptcha-action"]
				},
      ),
    {
      immediate: false,
    }
  );

  const apiAuthSignUpCheckEmail = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiSendEmailResponse>>(
        'v1/public/auth/check-email',
        payload,
      ),
    { immediate: false }
  );

  const apiAuthSignUpAbandonedCart = useRequest(
    (payload) =>
      alovaInstanceAuth.Post(
        'v1/public/auth/abandoned-cart',
        payload,
      ),
    { immediate: false }
  );

  const apiAuthSignUpValidateEmail = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiSignupEmailValidate>>(
        '/v1/public/auth/check-email/validate',
        payload,
      ),
    { immediate: false }
  );

  const apiAuthSignUpValidateContact = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiSignupEmailValidate>>(
        '/v1/public/auth/check-contact/validate',
        payload,
      ),
    { immediate: false }
  );

  const apiAuthSignUpCheckContact = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiSignupEmailValidate>>(
        '/v1/public/auth/check-contact',
        {
					...payload,
					document: removeMask(payload.document),
					phone: removeMask(payload.phone)
				},
      ),
    { immediate: false }
  );

  const apiAuthCustomerDebts = useRequest(
    alovaInstanceAuth.Get<ApiAuthResponse<SortingDebitResponse>>(
      '/v1/app/customer/debts',
    ),
    { immediate: false }
  );

  const apiAuthLogout = useRequest(
    alovaInstanceAuth.Post('v1/app/logout'),
    { immediate: false }
  );

	const apiAuthEngageSignin = useRequest(
    (token) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiSigninResponse>>(
        'v1/engage/signin',
        {},
        { headers: { 'Authorization': `Bearer ${token}` } },
      ),
      { immediate: false }
  );

  const apiAuthGenerateOTP = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiGenerateOTPResponse>>(
        '/v1/public/auth/signin/code',
        { ...transformGenerateOTPPayload(payload) }
      ),
    { immediate: false }
  );

  const apiAuthCheckOTP = useRequest(
    (payload) =>
      alovaInstanceAuth.Post<ApiAuthResponse<ApiSigninResponse>>(
        '/v1/public/auth/signin/code-check',
        { ...transformCheckOTPPayload(payload) }
      ),
    { immediate: false }
  );

  return {
    apiAuthSignin,
    apiAuthFacebookSignin,
    apiAuthRefreshToken,
    apiAuthSignup,
    apiAuthCheckDocument,
    apiAuthSignUpValidateContact,
    apiAuthSignUpCheckContact,
    apiAuthForgotPassword,
    apiAuthForgotPasswordValidate,
    apiAuthForgotPasswordLegacyValidate,
    apiAuthForgotPasswordLegacy,
    apiAuthForgotPasswordFind,
    apiAuthForgotPasswordReset,
    apiAuthForgotPasswordResetLegacy,
    apiAuthCustomer,
    apiAuthChangePassword,
    apiAuthDeleteAccount,
    apiAuthEmailSendToken,
    apiAuthEmailValidateTokenAndUpdate,
    apiAuthGetKbaQuestions,
    apiAuthSignUpCheckEmail,
    apiAuthSignUpValidateEmail,
    apiAuthCustomerDebts,
    apiAuthLogout,
    apiAuthForgotPasswordKBAQuestion,
    apiAuthForgotPasswordKBAAnswer,
    apiAuthForgotPasswordKBAReset,
		apiAuthSignUpAbandonedCart,
		apiAuthEngageSignin,
    apiAuthGenerateOTP,
    apiAuthCheckOTP
  };
};

export default useApiAuth;
