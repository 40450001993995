import { Conditional } from 'iq-blueberry';
import { Skeleton } from '@common/components/Skeleton';

import { useSkeletonCard } from './hook';

import classNames from 'classnames';
import './styles.scss';

export type SkeletonCardProps = {
  carouselUse?: boolean;
  fullSkeleton?: boolean;
  text?: 'initialPoolingText' | 'continuePollingText';
};

const SkeletonCard: React.FC<SkeletonCardProps> = ({
  carouselUse,
  fullSkeleton,
  text,
}) => {
  const cardClass = classNames('skeleton-card', {
    'skeleton-card--carousel-use': carouselUse,
  });

  const variants = useSkeletonCard();

  return (
    <div className={cardClass}>
      <div className="skeleton-card__header">
        <Skeleton height="40px" width="40px" borderRadius="8px" />
        <div>
          <Skeleton height="16px" width="72px" borderRadius="4px" />
          <Skeleton height="16px" width="96px" borderRadius="4px" />
          <Skeleton height="16px" width="112px" borderRadius="4px" />
        </div>
      </div>
      {/* TODO: add separator */}
      <div className="skeleton-card__divider" />
      <div className="skeleton-card__body">
        <Conditional
          condition={fullSkeleton === true && !text}
          renderIf={
            <div className="skeleton-card__body__fullSkeleton">
              <Skeleton height="16px" width="100%" borderRadius="4px" />
              <Skeleton height="16px" width="100%" borderRadius="4px" />
            </div>
          }
        />
        <Conditional
          condition={!!text && !fullSkeleton}
          renderIf={
            <p>
              {text && variants[text]?.firstPart}
              {text && <strong>{variants[text]?.strongPart}</strong>}
            </p>
          }
        />
      </div>
      {/* TODO: add separator */}
      <div className="skeleton-card__divider" />
      <div className="skeleton-card__footer">
        <Skeleton height="48px" width="100%" borderRadius="100px" />
      </div>
    </div>
  );
};

export default SkeletonCard;
