import { Conditional, Text } from 'iq-blueberry';
import { useErrorBanner } from './hook';

import './styles.scss';

interface ErrorBannerProps {
  title?: string;
  variant: 'score' | 'dados' | 'dividas';
  adsPath?: string;
  eventName?: string;
}

export const ErrorBanner: React.FC<ErrorBannerProps> = ({
  title,
  variant,
  eventName = 'generic',
}) => {
  const { variants } = useErrorBanner({
    eventName,
  });

  return (
    <div className="error">
      <Conditional
        condition={!!title}
        renderIf={
          <Text
            as="h1"
            variant="heading-four"
            variantDesk="heading-two"
            defaultColor="title"
          >
            {title}
          </Text>
        }
      />
      <div className="error__content">
        <div className="error__content__img">
          <img
            className={`img-${variant}`}
            src={variants[variant].src}
            alt={variants[variant].alt}
          />
        </div>

        <div className="error__content__text">
          <Text
            as="h2"
            variant="heading-six"
            variantDesk="heading-three"
            defaultColor="title"
          >
            {variants[variant].subtitle}
          </Text>
          <Text as="p" variant="body-small" variantDesk="body-large">
            No momento estamos tendo problemas para acessar suas informações,
            mas fique tranquilo, já estamos trabalhando para resolver isso.
          </Text>
        </div>
      </div>
    </div>
  );
};
