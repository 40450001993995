import { createPortal } from 'react-dom';
import { Button, Conditional, Text } from 'iq-blueberry';
import classNames from 'classnames';

import radioGreenIcon from '@common/assets/icon/radio-green.svg';
import radioGrayIcon from '@common/assets/icon/radio-gray.svg';
import radioIcon from '@common/assets/icon/radio.svg';
import warningIcon from '@common/assets/icon/warning.svg';
import qrCodeStores from '@common/assets/images/qr-code-one-link.svg';

import { useMonitoringStatus } from './hook';
import { Modal } from '../Modal';

import './styles.scss';

export const MonitoringStatus = () => {
  const {
    handleMonitoringStatusClick,
    handleStoreRedirect,
    isModalOpen,
    setIsModalOpen,
    statusMonitoring,
    isMobile,
  } = useMonitoringStatus();

  const statusColor = classNames('monitoring-status', {
    'monitoring-status--on': statusMonitoring,
    'monitoring-status--off': !statusMonitoring,
  });

  return (
    <>
      <div className={statusColor}>
        <Conditional
          condition={statusMonitoring}
          renderIf={
            <div className="monitoring-status__online">
              <img src={radioGreenIcon} alt="Ondas de radio verde" />
              <Text as="p" variant="body-medium" variantDesk="body-large">
                <strong>Seu monitoramento está ativo!</strong>
              </Text>
            </div>
          }
          renderElse={
            <div className="monitoring-status__offline">
              <img src={radioGrayIcon} alt="Ondas de radio cinza" />
              <div className="monitoring-status__offline__content">
                <Text as="p" variant="body-medium" variantDesk="body-large">
                  <strong>Você não está monitorando seu Score</strong>
                </Text>

                <button
                  className="monitoring-status__offline__link"
                  onClick={handleMonitoringStatusClick}
                >
                  Ativar monitoramento grátis
                </button>
              </div>
            </div>
          }
        />
      </div>
      {createPortal(
        <Modal
          showModal={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          mobile="center"
          desktop="center"
        >
          <div className="modal__content">
            <div className="modal__header">
              <img
                src={radioIcon}
                alt="Icone de monitoramento"
                className="modal__icon"
              />
              <Text as="h2" variant="heading-five" variantDesk="heading-three">
                Monitore seu Score Grátis
              </Text>
              <Text as="p" variant="body-medium" variantDesk="body-large">
                Agora você recebe alertas sobre mudanças da sua pontuação,
                objetivos, dicas e muito mais!
              </Text>
            </div>

            {!window.isNativeApp && (
              <Conditional
                condition={isMobile}
                renderIf={
                  <div className="modal__body">
                    <div
                      className="card__container"
                      onClick={handleStoreRedirect}
                    >
                      <img
                        src={warningIcon}
                        alt="Icone de alerta"
                        className="card__icon"
                      />
                      <Text
                        as="h3"
                        variant="body-small"
                        variantDesk="body-medium"
                      >
                        <strong>
                          Você precisa ter nosso aplicativo de celular para
                          receber as notificações de monitoramento.
                          <p
                            className="card__link"
                            aria-label="Ir para a loja de aplicativos"
                          >
                            Baixe clicando aqui.
                          </p>
                        </strong>
                      </Text>
                    </div>
                  </div>
                }
                renderElse={
                  <div className="modal__body">
                    <div className="card__container">
                      <img
                        src={warningIcon}
                        alt="Icone de alerta"
                        className="card__icon"
                      />
                      <Text
                        as="h3"
                        variant="body-small"
                        variantDesk="body-medium"
                      >
                        <span className="card__text-qr">
                          Você precisa ter nosso aplicativo de celular para
                          receber as notificações de monitoramento.
                        </span>{' '}
                        <strong>
                          Escaneie o QR Code ao lado e baixe nosso app.
                        </strong>
                      </Text>
                    </div>
                    <img
                      className="card__qr-code"
                      src={qrCodeStores}
                      alt="QR Code"
                    />
                  </div>
                }
              />
            )}

            <div className="modal__footer">
              <Button
                size="large"
                type="primary"
                color="default"
                loading={false}
                disabled={false}
                expand="x"
                onClick={() => setIsModalOpen(false)}
              >
                Voltar
              </Button>
            </div>
          </div>
        </Modal>,
        document.body
      )}
    </>
  );
};
