import { useCallback, useState } from 'react';
import { ConfirmDialog } from '.';
import React from 'react';

export interface useConfirmProps {
  title: string;
  message: string;
  buttonFalse?: string;
  buttonTrue?: string;
  iconButtonFalse?: React.ReactNode;
  iconButtonTrue?: React.ReactNode;
}

const useConfirmDialog = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [resolvePromise, setResolvePromise] = useState<
    ((value: boolean) => void) | null
  >(null);
  const [dialogProps, setDialogProps] = useState<useConfirmProps>();

  const confirm = useCallback(
    ({
      title,
      message,
      buttonFalse,
      buttonTrue,
      iconButtonFalse,
      iconButtonTrue,
    }: useConfirmProps) => {
      setDialogProps({
        title,
        message,
        buttonFalse,
        buttonTrue,
        iconButtonFalse,
        iconButtonTrue,
      });
      setIsVisible(true);

      return new Promise<boolean>(async (resolve) => {
        await setResolvePromise(() => resolve);
      });
    },
    []
  );

  const result = useCallback(
    (value: boolean) => {
      setIsVisible(false);
      if (resolvePromise) {
        resolvePromise(value);
      }
    },
    [resolvePromise]
  );

  const dialog = React.useMemo(() => {
    const props = {
      title: dialogProps?.title,
      message: dialogProps?.message,
      buttonFalse: dialogProps?.buttonFalse,
      buttonTrue: dialogProps?.buttonTrue,
      iconButtonFalse: dialogProps?.iconButtonFalse,
      iconButtonTrue: dialogProps?.iconButtonTrue,
      showConfirmDialog: isVisible,
      onConfirm: () => result(true),
      onCancel: () => result(false),
    };

    return <ConfirmDialog {...props} />;
  }, [dialogProps, isVisible, result]);

  return {
    confirm,
    ConfirmDialog: dialog,
  };
};

export default useConfirmDialog;
