import { Button, Conditional, Text } from 'iq-blueberry';
import './styles.scss';
import { useError } from './hook';
import { Link } from 'react-router-dom';
import { RouteMap } from '@common/constants/RouteMap';
interface ErrorProps {
  title?: string;
  variant: 'score' | 'dados' | 'notFound';
  adsPath?: string;
  eventName?: string;
  hideBackButton?: boolean;
  bvs?: boolean;
}

export const Error: React.FC<ErrorProps> = ({
  title,
  variant,
  adsPath,
  eventName = 'generic',
  hideBackButton = false,
  bvs = false,
}) => {
  const { variants, handleGoBack } = useError({
    eventName,
  });

  return (
    <div className="error">
      <Conditional
        condition={!!title}
        renderIf={
          <Text
            as="h1"
            variant="heading-four"
            variantDesk="heading-two"
            defaultColor="title"
          >
            {title}
          </Text>
        }
      />
      <div className="error__content">
        <div className="error__content__img">
          <img
            className={`img-${variant}`}
            src={variants[variant].src}
            alt={variants[variant].alt}
          />
        </div>

        <div className="error__content__text">
          <Text
            as="h2"
            variant="heading-six"
            variantDesk="heading-three"
            defaultColor="title"
          >
            {variants[variant].subtitle}
          </Text>
          <Conditional
            condition={bvs}
            renderIf={
              <>
                <Text as="p" variant="body-small" variantDesk="body-large">
                  Estamos enfrentando instabilidade na fonte dos dados fornecidos pela Equifax Boa Vista,{' '}
                  <strong>o que impossibilita o acesso às informações no momento</strong>.
                </Text>
                <Text as="p" variant="body-small" variantDesk="body-large">
                  Caso tenha dúvidas ou precise de mais informações, entre em contato através da{' '}
                  <Link to={RouteMap.Help}>página de atendimento</Link> ou pelos telefones:
                </Text>
                <Text as="p" variant="body-small" variantDesk="body-large">
                  <li>Atendimento ao consumidor: <strong>3003-0201</strong>;</li>
                  <li>Cadastro Positivo: <strong>0800 727 0201</strong>.</li>
                </Text>
              </>

            }
            renderElse={
              <Text as="p" variant="body-small" variantDesk="body-large">
                No momento estamos tendo problemas para acessar algumas informações,
                mas fique tranquilo, já estamos trabalhando para resolver isso.
              </Text>
            }
          />

        </div>
      </div>
      <Conditional
        condition={!hideBackButton}
        renderIf={
          <Button
            onClick={handleGoBack}
            type="secondary"
            size={'medium'}
            justify={'center'}
            color={'default'}
            disabled={false}
            loading={false}
            expand={'x'}
          >
            Voltar
          </Button>
        }
      />
    </div>
  );
};
