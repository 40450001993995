import './styles.scss';
import { Conditional } from 'iq-blueberry';
import { useTabs } from './hooks';

export interface TabsProps {
  tabsContent: Array<{ title: string; content: JSX.Element | JSX.Element[] }>;
  customIndex?: number;
  handleChange?: (tabIndex: number) => void;
  handleClick?: (tabIndex: number) => void;
}

export const Tabs: React.FC<TabsProps> = ({
  tabsContent,
  customIndex = 0,
  handleChange,
  handleClick,
}) => {
  const { getIndex, changeTabClick } = useTabs({
    customIndex,
    handleChange,
    handleClick,
  });

  return (
    <div className="tabs">
      <div className="tabs__header">
        {tabsContent.map((tab, i) => (
          <div
            key={i}
            className={i === getIndex ? 'tabs__item active' : 'tabs__item'}
            onClick={() => changeTabClick(i)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      {tabsContent.map((tab, i) => (
        <Conditional
          key={i}
          condition={i === getIndex}
          renderIf={<div className="tabs__content">{tab.content}</div>}
        />
      ))}
    </div>
  );
};
