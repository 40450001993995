import { Button, Conditional, Text } from 'iq-blueberry';

import { Modal } from '@common/components/Modal';
import { RouteMap } from '@common/constants/RouteMap';

import iconSettings from '../../assets/icon/filled-settings.svg';
import iconBell from '../../assets/icon/filled-bell.svg';
import iconPaperPlane from '../../assets/icon/filled-paper-plane.svg';

import { useCreditProfileOverview } from './hook';
import { content } from './data';
import './styles.scss';

import { DebtsOfferBanner } from '@dues/components/DebtsOfferBanner';
import { CardUpdateApp } from '@common/components/CardUpdateApp';
import { AppStoresBanner } from '@common/components/AppStoresBanner';
import { ReportCpfBanner } from '@common/components/ReportCpfBanner';
import { DeniedPushNotificationBanner } from '@common/components/DeniedPushNotificationBanner';

export const CreditProfileOverview = () => {
  const { handleNavigate, handleRedirect } = useCreditProfileOverview();

  return (
    <>
      <div className="credit-profile-overview">
        <div className="credit-profile-overview__debts-container">
          <CardUpdateApp style={{ marginBottom: '32px' }} />
          <DeniedPushNotificationBanner text="Seja notificado quando tiver dívidas para negociar." />
          <DebtsOfferBanner />
          <Conditional
            condition={!window.isNativeApp}
            renderIf={
              <div className="credit-profile-overview__stores-banner">
                <AppStoresBanner />
              </div>
            }
          />
        </div>
        <div className="credit-profile-overview__container--filled">
          <div className="credit-profile-overview__monitor">
            <div className="credit-profile-overview__monitor__header">
              <Text
                className="credit-profile-overview__monitor__header__title"
                as="h4"
                variant="heading-five"
                variantDesk="heading-three"
                defaultColor="title"
              >
                Veja o que consta em seu CPF
              </Text>
            </div>

            <div className="credit-profile-overview__monitor__content">
              <div
                className="credit-profile-overview__monitor__card"
                onClick={() => handleNavigate(RouteMap.NegativeDebts)}
              >
                <div className="credit-profile-overview__monitor__card__content">
                  <img
                    src={content.cards[0].icon.src}
                    alt={content.cards[0].icon.alt}
                  />
                  <Text
                    className="credit-profile-overview__monitor__card__text"
                    as="p"
                  >
                    Dívidas negativadas
                  </Text>
                </div>
                <div className="credit-profile-overview__monitor__card__content">
                  <img
                    src={content.chevronIcon.src}
                    alt={content.chevronIcon.alt}
                  />
                </div>
              </div>

              {/* <div
                className="credit-profile-overview__monitor__card"
                onClick={() => handleNavigate(RouteMap.BillsAndPayments)}
              >
                <div className="credit-profile-overview__monitor__card__content">
                  <img
                    src={content.cards[1].icon.src}
                    alt={content.cards[1].icon.alt}
                  />
                  <Text
                    className="credit-profile-overview__monitor__card__text"
                    as="p"
                  >
                    Contas e pagamentos
                  </Text>
                </div>
                <div className="credit-profile-overview__monitor__card__content">
                  <div className="credit-profile-overview__monitor__card__content">
                    <img
                      src={content.chevronIcon.src}
                      alt={content.chevronIcon.alt}
                    />
                  </div>
                </div>
              </div> */}

              <div
                className="credit-profile-overview__monitor__card"
                onClick={() => handleNavigate(RouteMap.CpfLookout)}
              >
                <div className="credit-profile-overview__monitor__card__content">
                  <img
                    src={content.cards[2].icon.src}
                    alt={content.cards[2].icon.alt}
                  />
                  <Text
                    className="credit-profile-overview__monitor__card__text"
                    as="p"
                  >
                    Quem consultou seu CPF
                  </Text>
                </div>
                <div className="credit-profile-overview__monitor__card__content">
                  <div className="credit-profile-overview__monitor__card__content">
                    <img
                      src={content.chevronIcon.src}
                      alt={content.chevronIcon.alt}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="credit-profile-overview__monitor__report">
              <hr className="credit-profile-overview__monitor__report__divider" />
              <ReportCpfBanner />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
