import { IconSend, Text } from '@consumidor-positivo/aurora';
import ToastManager from '@common/components/ToastManager';
import { createPortal } from 'react-dom';

import reportIcon from '@common/assets/icon/cp/report-list.svg';

import { useReportCpfBanner } from './hook';
import './styles.scss';

export const ReportCpfBanner = () => {
  const {
    toastType,
    toastMessage,
    toastShow,
    toastKey,
    reportRef,
    handleAction,
    ConfirmDialog,
  } = useReportCpfBanner();

  return (
    <>
      <div className="report-banner" onClick={handleAction} ref={reportRef}>
        <div className="report-banner__content">
          <div className="report-banner__text">
            <Text
              as="p"
              variant="body-small"
              variantDesk="body-large"
              className="report-banner__text__title"
            >
              Relatório do meu CPF <strong>| Grátis</strong>
            </Text>
            <Text
              as="p"
              variant="body-small"
              variantDesk="body-large"
              className="report-banner__text__content"
            >
              Tenha em um só lugar seu{' '}
              <strong>
                Score consultas feitas em seu CPF e suas negativações.{' '}
              </strong>
            </Text>
          </div>
          <img src={reportIcon} alt="Relatório" />
        </div>

        <div className="report-banner__action">
          <IconSend className="report-banner__action__img" />
          <Text as="p" variant="body-small" weight="semibold">
            Enviar relatório para meu e-mail
          </Text>
        </div>
      </div>

      <ToastManager
        key={toastKey}
        toastType={toastType}
        toastMessage={toastMessage}
        toastShow={toastShow}
      />

      {createPortal(ConfirmDialog, document.body)}
    </>
  );
};
