import { TypesConfigOptional } from 'react-spinetic/types';
import { Spinetic, SpineticItem } from 'react-spinetic';
import { Button, Conditional, Text } from 'iq-blueberry';
import { LoaderCircular } from '@common/components/LoaderCircular';
import { AppStoresBanner } from '@common/components/AppStoresBanner';
import { CardUpdateApp } from '@common/components/CardUpdateApp';
import { DeniedPushNotificationBanner } from '@common/components/DeniedPushNotificationBanner';

import { MatchMakerCard } from '../MatchMakerCard';
import { HubCard } from '../HubCard';
import { useOffersOverview } from './hook';
import './styles.scss';

export const OffersOverview = () => {
  const { goToOffers, shDecisionList } = useOffersOverview();

  const spineticConfig: TypesConfigOptional = {
    showItems: 2,
    groupScroll: true,
    groupItemsScroll: 2,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          arrows: false,
          autoWidth: true,
          centerSingleItemAW: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          autoWidth: true,
        },
      },
    ],
  };

  return (
    <div className="offers-overview">
      <div className="offers-overview__cards__container">
        <CardUpdateApp style={{ marginBottom: '32px' }} />
        <DeniedPushNotificationBanner text="Ative suas notificações para receber ofertas de crédito." />
        <MatchMakerCard />
      </div>

      <div className="offers-overview__cta">
        <div className="offers-overview__cta__title">
          <Text
            as="h4"
            variant="heading-five"
            variantDesk="heading-three"
            defaultColor="title"
          >
            Confira as ofertas selecionadas de acordo com seu Score
          </Text>
        </div>
        <div className="offers-overview__link-button">
          <Button
            color="default"
            disabled={false}
            expand="x"
            iconRight
            justify="center"
            loading={false}
            size="medium"
            type="secondary"
            onClick={goToOffers}
          >
            Conhecer mais ofertas
          </Button>
        </div>
      </div>

      <Conditional
        condition={shDecisionList?.length === 0}
        renderIf={
          <div className="offers-overview__loading">
            <LoaderCircular />
          </div>
        }
      />

      <Conditional
        condition={shDecisionList?.length > 0}
        renderIf={
          <div className="offers-overview__carousel">
            <Spinetic config={spineticConfig}>
              {shDecisionList.map((item, index) => (
                <SpineticItem key={`offerCard-mob-${index}`}>
                  <div className="offers-overview__carousel__item">
                    <HubCard
                      offer={item}
                      position={index}
                      cardLocation="modal-ofertas" // FIXME: KEEP old location - maybe we should change????
                    />
                  </div>
                </SpineticItem>
              ))}
            </Spinetic>
          </div>
        }
      />
      <Conditional
        condition={!window.isNativeApp}
        renderIf={
          <div className="container stores-banner">
            <AppStoresBanner />
          </div>
        }
      />

      <div className="container offers-overview__link-button offers-overview__link-button--bottom">
        <Button
          color="default"
          disabled={false}
          expand="x"
          iconRight
          justify="center"
          loading={false}
          size="medium"
          type="secondary"
          onClick={goToOffers}
        >
          Conhecer mais ofertas
        </Button>
      </div>
    </div>
  );
};
