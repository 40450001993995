import billFoldingCP from '../../assets/bill-folding-cp.svg';
import moneyFoldingCP from '../../assets/bill-money-cp.svg';
import fileChecklistCP from '../../assets/file-checklist-cp.svg';
import chevronRightIconCP from '../../assets/chevron-right-cp.svg';
import lockCP from '../../assets/icon/filled-lock.svg';

const data = {
  cards: [
    {
      icon: {
        src: billFoldingCP,
        alt: 'Folha em roxo claro com o simbolo de dinheiro em roxo escuro ao centro',
      },
    },
    {
      icon: {
        src: moneyFoldingCP,
        alt: 'Icone de um boleto em roxo claro sobreposto por icone de dinheiro em roxo escuro',
      },
    },
    {
      icon: {
        src: fileChecklistCP,
        alt: 'Papel com a borda superior direita dobrada em roxo claro com a representação de uma lista em roxo escuro ao centro',
      },
    },
  ],
  chevronIcon: {
    src: chevronRightIconCP,
    alt: 'Seta apontada a direita em roxo claro',
  },
  lockIcon: {
    src: lockCP,
    alt: 'Icone de um cadeado verde',
  },
};

export const content = data;
