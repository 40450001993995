import { useEffect, useState } from 'react';

import { useTracking } from '@common/hooks/useTracking';
import { StorageMap } from '@common/constants/StorageMap';

import { useUserContext } from '@common/contexts/UserContext';
import { handleErrorLog } from '@common/utils/handleErrorLogs';
import { getFromStorage, setOnStorage } from '@common/utils/handleStorage';

import { UserScore } from '@tally/types';
import { useScore } from '@tally/hooks/useScore';
import { User } from '@admission/apis/auth/types';
import { useSortingDebts } from '@dues/hooks/useSortingDebt';

export const useDashboard = () => {
  const { getScore } = useScore();
  const { user, getUser } = useUserContext();
  const { elementClickedEvent, elementViewedEvent } = useTracking();
  useSortingDebts();

  const [score, setScore] = useState<UserScore | null>(null);
  const [hasError, setHasError] = useState(false);
  const [loadingDashboard, setLoadingDashboard] = useState(true);
  const [isNegativeConsumer, setIsNegativeConsumer] = useState(false);
  const [customIndex, setCustomIndex] = useState(1);

  useEffect(() => {
    if (!user) getUser();
    else {
      setIsNegativeConsumer(!user?.positiveCustomer);
      getTabFromStorage(user);
      handleComponentsPosition();
    }
  }, [user, isNegativeConsumer]);

  async function handleComponentsPosition() {
    try {
      const hasScore = !!score;
      const scoreRes = hasScore ? score : await getScore();
      if (!scoreRes) throw new Error('skip');
      setScore(scoreRes);
      setHasError(false);
    } catch (err) {
      handleErrorLog(err, { notLogMessage: ['skip'] });
      setHasError(true);
    } finally {
      setLoadingDashboard(false);
    }
  }

  function handleTabChange(index: number) {
    const indexMap = {
      0: 'score',
      1: 'offer',
      2: 'cpf',
    } as { [key: number]: string };

    const name = indexMap[index] === 'offer' ? 'credito' : indexMap[index];

    elementClickedEvent({
      elementType: 'content',
      location: '/home',
      position: 'dashboard-tab',
      name,
    });

    setOnStorage('session', StorageMap.DashTab, indexMap[index]);
  }

  function getTabFromStorage(user?: User | null) {
    const indexMap: { [key: string]: number } = {
      score: 0,
      offer: 1,
      cpf: 2,
    };
    const sessionTab = getFromStorage<string>(StorageMap.DashTab);
    if (sessionTab) return setCustomIndex(indexMap[sessionTab]);

    const urlParams = getFromStorage<string>(StorageMap.UrlParams);
    const tabParamValue = new URLSearchParams(urlParams || '').get('tab');
    if (tabParamValue) {
      const safeValue = tabParamValue === 'credito' ? 'offer' : tabParamValue;
      setOnStorage('session', StorageMap.DashTab, safeValue);
      return setCustomIndex(indexMap[safeValue]);
    }

    if (user && user?.experience) {
      setOnStorage('session', StorageMap.DashTab, user?.experience || 'offer');

      let text = 'credito';
      if (indexMap[user?.experience] === 0) text = 'score';
      else if (indexMap[user?.experience] === 2) text = 'cpf';

      const name = user?.experience === 'offer' ? 'credito' : user?.experience;
      elementViewedEvent({
        elementType: 'tab',
        location: '/home',
        name,
        text,
      });

      return setCustomIndex(indexMap[user?.experience || 'offer']);
    }
  }

  return {
    loadingDashboard,
    score,
    hasError,
    isNegativeConsumer,
    customIndex,
    handleTabChange,
  };
};
