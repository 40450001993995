import { useEffect, useState } from 'react';

import { getMonth } from '@libs/utils/helpers/getMonth';
import { delay } from '@libs/utils/helpers/delay';

import { scoreRanges } from '@tally/constants/scoreRanges';
import { ScoreRange, UserScore } from '@tally/types';
interface IUseScoreGauge {
  score: UserScore;
}
export const useScoreGauge = ({ score }: IUseScoreGauge) => {
  const [lastUpdateMessage, setLastUpdateMessage] = useState('-');
  const [chances, setChances] = useState('-');
  const [scoreAnimated, setScoreAnimated] = useState(0);
  const [gaugeValue, setGaugeValue] = useState(50);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const gaugeArcOptions = [
    {
      limit: 199,
      color: scoreRanges[ScoreRange.Red].color,
      length: 0.2,
    },
    {
      limit: 399,
      color: scoreRanges[ScoreRange.Orange].color,
      length: 0.2,
    },
    {
      limit: 599,
      color: scoreRanges[ScoreRange.Yellow].color,
      length: 0.2,
    },
    {
      limit: 799,
      color: scoreRanges[ScoreRange.GreenLight].color,
      length: 0.2,
    },
    {
      limit: 1000,
      color: scoreRanges[ScoreRange.GreenDark].color,
      length: 0.2,
    },
  ];

  useEffect(() => {
    handleGaugeValue();
    handleScoreAnimation();
    handleChancesText();
    handleLastUpdate();
  }, [score]);

  function handleGaugeValue() {
    const { value, detail } = score;

    let scoreEndRange = 0;
    let gaugeEndRange = 0;

    switch (detail.slug) {
      case ScoreRange.Red:
        scoreEndRange = scoreRanges[ScoreRange.Red].endRange;
        gaugeEndRange = gaugeArcOptions[0].limit;
        break;
      case ScoreRange.Orange:
        scoreEndRange = scoreRanges[ScoreRange.Orange].endRange;
        gaugeEndRange = gaugeArcOptions[1].limit;
        break;
      case ScoreRange.Yellow:
        scoreEndRange = scoreRanges[ScoreRange.Yellow].endRange;
        gaugeEndRange = gaugeArcOptions[2].limit;
        break;
      case ScoreRange.GreenLight:
        scoreEndRange = scoreRanges[ScoreRange.GreenLight].endRange;
        gaugeEndRange = gaugeArcOptions[3].limit;
        break;
      default:
        scoreEndRange = scoreRanges[ScoreRange.GreenDark].endRange;
        gaugeEndRange = gaugeArcOptions[4].limit;
        break;
    }

    const percent = getRangePercent(scoreEndRange, value);
    const recalculateValue = getProportionalGaugeValue(gaugeEndRange, percent);
    const pointerValue = getFixedGaugeValue(recalculateValue);
    setGaugeValue(pointerValue);
  }

  async function handleScoreAnimation() {
    const { value } = score;

    let i = 0;
    const timer = 85;
    let numberToSum = 7;

    if (value >= 150 && value < 300) numberToSum = 12;
    else if (value >= 300 && value < 500) numberToSum = 17;
    else if (value >= 500 && value < 800) numberToSum = 28;
    else if (value > 800) numberToSum = 39;

    while (i < value) {
      await delay(timer);
      i += numberToSum;
      setScoreAnimated(i > value ? value : i);
      setAnimationFinished(i >= value);
    }
  }

  function handleLastUpdate() {
    const { lastUpdate } = score;

    if (!score.lastUpdate) return;
    const normalizeSeparator = lastUpdate.replace(/[^\d]/gm, '/');
    const [day] = normalizeSeparator.split('/');
    const formattedDay = Number(day) < 10 ? `0${Number(day)}` : day;
    const month = getMonth(lastUpdate);

    const message = month?.short ? `${formattedDay}/${month?.short}` : ' - ';

    setLastUpdateMessage(`Score atualizado ${message}`);
  }

  function handleChancesText() {
    if (!score?.detail) return;

    const { slug } = score.detail;
    let chance = 'muito baixa';

    if (slug === ScoreRange.Orange) chance = 'baixa';
    else if (slug === ScoreRange.Yellow) chance = 'média';
    else if (slug === ScoreRange.GreenLight) chance = 'alta';
    else if (slug === ScoreRange.GreenDark) chance = 'muito alta';

    setChances(`está ${chance}`);
  }

  function getRangePercent(endRangeValue: number, score: number) {
    const percentage = (score / endRangeValue) * 100;
    return percentage;
  }

  function getProportionalGaugeValue(
    gaugeEndRangeValue: number,
    rangePercent: number
  ) {
    const value = (rangePercent / 100) * gaugeEndRangeValue;
    return Math.floor(value);
  }

  function getFixedGaugeValue(value: number) {
    if (value < 50) return 50;
    if (value > 953) return 953;

    return value;
  }

  return {
    lastUpdateMessage,
    chances,
    scoreAnimated,
    animationFinished,
    gaugeArcOptions,
    gaugeValue,
    isModalOpen,
    setIsModalOpen,
  };
};
