import { createPortal } from 'react-dom';

import { Conditional, Button, Text } from 'iq-blueberry';

import { Modal } from '@common/components/Modal';
import { DebtResume } from '@dues/types/sorting-debt';

import acordoCerto from '@dues/assets/logo-acordo-certo.svg';
import fireEmoji from '@dues/assets/fire-emoji.svg';
import question from '@dues/assets/icon/question.svg';

import './styles.scss';
import classNames from 'classnames';

import { useDebtsCard } from './hook';

export type DebtsCardProps = {
  debt: DebtResume;
  position: number;
  carouselUse?: boolean;
  isNegative?: boolean;
  negativeDate?: string;
};

export const DebtsCard: React.FC<DebtsCardProps> = ({
  debt,
  position,
  carouselUse,
  isNegative,
  negativeDate,
}) => {
  const {
    debtRef,
    isModalOpen,
    handleNegotiate,
    toggleModal,
    loadingRedirect,
  } = useDebtsCard({
    debt,
    position,
  });

  const cardClass = classNames('debts-card', {
    'debts-card--carousel-use': carouselUse,
  });

  const headerTextClass = classNames('debts-card__header__texts', {
    'debts-card__header__texts--no-wrap': !debt?.hasBvPartnership || false,
  });

  const headerAlign = classNames('debts-card__header', {
    'debts-card__header--neg-debts-align': isNegative,
  });

  return (
    <div className={cardClass} ref={debtRef}>
      <div className={headerAlign}>
        <img src={debt.image.src} alt={debt.image.alt} width={40} height={40} />

        <div className={headerTextClass}>
          <Conditional
            condition={!!isNegative}
            renderIf={
              <div className="debts-card__header__neg-debts">
                <Text
                  as="p"
                  variant="body-small"
                  className="debts-card__header__details"
                >
                  Contrato: <strong>{debt?.contract}</strong>
                </Text>
                <Text
                  as="p"
                  variant="body-small"
                  className="debts-card__header__details"
                >
                  Data de negativação: <strong>{negativeDate}</strong>
                </Text>
              </div>
            }
            renderElse={
              <>
                <Text as="h3" variant="body-medium" variantDesk="body-medium">
                  <strong>{debt.partnerName}</strong>
                </Text>

                <Conditional
                  condition={debt?.hasBvPartnership || false}
                  renderIf={
                    <Text as="p" variant="body-small">
                      {debt.partnerMessage}
                    </Text>
                  }
                  renderElse={
                    <>
                      <Text
                        as="span"
                        variant="body-small"
                        className="text-overflow"
                      >
                        {debt.productName}
                      </Text>
                      <DebtDetail debt={debt} toggleModal={toggleModal} />
                    </>
                  }
                />
              </>
            }
          />
        </div>
      </div>

      {/* TODO: add separator */}
      <div className="debts-card__divider" />

      <div className="debts-card__content">
        <div className="debts-card__content__values">
          <Text as="p" variant="body-small" variantDesk="body-medium">
            De{' '}
            <strong>
              <s>{debt.debtValue}</s>
            </strong>{' '}
            por
          </Text>
          <Text as="h3" variant="body-large">
            <strong>{debt.agreementValue}</strong>
          </Text>
        </div>

        <Conditional
          condition={debt.discountValue >= 10}
          renderIf={
            <div className="debts-card__discount">
              <Text as="span" variant="body-small">
                desconto de
              </Text>
              <div className="debts-card__discount__percentage">
                <img src={fireEmoji} alt="Emoji de fogo indicando desconto" />
                <Text as="p" variant="body-large">
                  {debt.discountValue}%
                </Text>
              </div>
            </div>
          }
        />
      </div>

      <div className="debts-card__divider" />

      <div className="debts-card__action">
        <Button
          type="primary"
          color="default"
          loading={loadingRedirect}
          expand="x"
          size="medium"
          justify="center"
          disabled={loadingRedirect}
          onClick={handleNegotiate}
        >
          Quero negociar
        </Button>
      </div>

      {createPortal(
        <Modal
          showModal={isModalOpen}
          mobile="center"
          desktop="center"
          onClose={toggleModal}
        >
          <div className="debt-modal">
            <div className="debt-modal__content">
              <img src={acordoCerto} alt="Logo da empresa Acordo Certo" />

              <Text as="h2" variant="heading-five" variantDesk="heading-three">
                Esta é uma dívida negativada com {debt.partnerName}
              </Text>
              <Text as="p" variant="body-medium" variantDesk="body-large">
                Você possui uma dívida negativada com a empresa{' '}
                <strong>{debt.partnerName}</strong> que está afetando
                negativamente seu histórico de crédito. Isso pode dificultar a
                obtenção de empréstimos, financiamentos ou até mesmo aluguel de
                imóveis no futuro. Renegociá-la é fundamental para limpar seu
                nome e recuperar seu crédito.
              </Text>
            </div>
            <Button
              type="primary"
              color="default"
              loading={false}
              expand="y"
              size="medium"
              justify="center"
              disabled={false}
              onClick={toggleModal}
            >
              Voltar
            </Button>
          </div>
        </Modal>,
        document.body
      )}
    </div>
  );
};

type DebtDetailProps = {
  debt: DebtResume;
  toggleModal: () => void;
};
const DebtDetail: React.FC<DebtDetailProps> = ({ debt, toggleModal }) => {
  if (debt?.debtsIsNegative) {
    return (
      <div className="debts-card__header__negative">
        <Text as="span" variant="body-small">
          <strong>Dívida negativada</strong>
        </Text>
        <img
          src={question}
          alt="Icone de um circulo com um ponto de interrogação no centro."
          onClick={toggleModal}
          width={14.5}
          height={14.5}
        />
      </div>
    );
  }

  return (
    <Text as="p" variant="body-small" className="debts-card__header__details">
      <Conditional
        condition={!!debt?.source}
        renderIf={
          <>
            Origem: <strong>{debt?.source}</strong>
          </>
        }
        renderElse={
          <>
            Contrato: <strong>{debt?.contract}</strong>
          </>
        }
      />
    </Text>
  );
};
